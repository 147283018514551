// 引入axios实例
import request from "./http";

async function Loaditem(data) {
  return await request({
    method: "get",
    url: "_3D/GetDragResoures",
    data,
  });
}
function GetTaskStatus(data) {
  return request({
    method: "get",
    url: "CadTask/GetTaskStatus",
    data,
  });
}
async function AutoLayout(data) {
  return await request({
    method: "post",
    url: "_3D/AutoLayout",
    data,
  });
}

function AddCadTask(data) {
  return request({
    method: "post",
    url: "CadTask/AddCadTask",
    data,
  });
}
function SendEmail(data) {
  return request({
    method: "post",
    url: "CadTask/SendEmail",
    data,
  });
}
async function Download(data) {
  return await request({
    method: "get",
    url: `File/Download?fileId=${data}`,
    data,
    responseType: "blob" // 表明返回服务器返回的数据类型
  });
}
export { Loaditem,GetTaskStatus,AutoLayout,AddCadTask,SendEmail,Download};
