<template>
  <div style="padding-bottom: 20px">
    <div v-show="status != 'drawing'">
    <!-- <div> -->
      <div class="two" v-show="!show3D">
        <img
          @click="open3d"
          v-show="!show3D"
          src="./../assets/images/threed.png"
          alt=""
        />
        <div class="pixi">
          <div
            id="pixi"
            ref="pixi"
            :style="{
              transform:
                'rotate(' +
                dataObj.tuzhiRotation +
                'deg) translateZ(0) translate3d(0,0,0) scale(' +
                dataObj.tuzhiScale +
                ')',
              height: pixiheight + 'vh'
            }"
          ></div>
        </div>
      </div>
      <div class="functionContainer" v-show="!show3D">
        <div class="dragBox" v-if="status == 'drag'">
          <p>添加第三方模块</p>
          <!-- :src="`${baseUrlApi}File/Download?fileId=${value.web2D}`" -->
          <ul class="img-box">
            <li
              v-for="(value, key, index) in itemJson" :key="index"
              v-if="value != null && value.web2DShape != null"
            >
              <img
                class="img-item"
                :src="imgList[index - 1]"
                @touchend.stop="dragDown(textList[index - 1])"
              />
              <!-- <p>{{ value.web2DShape.name }}</p> -->
              <p>{{ textList[index - 1] }}</p>
            </li>
          </ul>
         
          <div class="content-btn" @click="dragNextStep">下一步</div>
        </div>
        <div class="planBox" v-if="status == 'plan'">
          <p>自动方案比选</p>
          <ul v-if="this.fanganjson.layoutSchemeExports.length === 3">
            <li
              @click="choosePlan(index)"
              :class="{ act: planIndex == index }"
              v-for= "(value, index) in planItem"
              :key="index"
            >
              <img
                ref="planRef"
                :src="planIndex == index ? value.activeIcon : value.icon"
                alt=""
              />
            </li>
          </ul>
          <ul v-if="this.fanganjson.layoutSchemeExports.length === 2">
            <li
              @click="choosePlan(index)"
              :class="{ act: planIndex == index }"
              v-for= "(value, index) in planItem2"
              :key="index"
            >
              <img
                ref="planRef"
                :src="planIndex == index ? value.activeIcon : value.icon"
                alt=""
              />
            </li>
          </ul>
          <ul v-if="this.fanganjson.layoutSchemeExports.length === 1">
            <li
              @click="choosePlan(index)"
              :class="{ act: planIndex == index }"
              v-for= "(value, index) in planItem3"
              :key="index"
            >
              <img
                ref="planRef"
                :src="planIndex == index ? value.activeIcon : value.icon"
                alt=""
              />
            </li>
          </ul>
          <div class="content-btn" @click="planNextStep">下一步</div>
        </div>
        <div class="adjustmentBox" v-if="status == 'adjustment'">
          <p>机架</p>
          <div @click="updataAutoLayout" class="updataBtn">刷新</div>
          <!-- 真实的机架x坐标 -->
          <van-field
            @focus="focusEvent('机架X坐标')"
            input-align="right"
            v-model="jijiax"
            :border="false"
            readonly
            label="机架X坐标"
            placeholder="未输入"
            type="number"
            v-show="!jijiaxRight"
          />
          <!--用来展示的机架x坐标 -->
           <van-field
            @focus="focusEvent('机架X坐标')"
            input-align="right"
            v-model="jijiaxRight"
            :border="false"
            readonly
            label="机架X坐标"
            placeholder="未输入"
            type="number"
          />
          <van-field
            @focus="focusEvent('机架Y坐标')"
            input-align="right"
            v-model="jijiay"
            :border="false"
            readonly
            label="机架Y坐标"
            placeholder="未输入"
            type="number"
          />
          <div
            class="handPosition"
            v-if="dataObj.systemName.indexOf('Symbia') == 0"
          >
            <span>机架左右手位</span>
            <div class="right">
              <van-radio-group
                @change="radioChange"
                v-model="handPosition"
                direction="horizontal"
              >
                <van-radio name="0" icon-size="14px" checked-color="#EC6602"
                  >左手位</van-radio
                >
                <van-radio
                  class="rightHand"
                  name="1"
                  icon-size="14px"
                  checked-color="#EC6602"
                  >右手位
                </van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="handPosition">
            <span>机架角度</span>
            <span>{{ computedAngle }}</span>
          </div>
          <div class="sliderBox">
            <van-slider
              v-model="gantryAngle"
              active-color="#fff"
              :step="5"
              :min="-90"
              :max="90"
            >
              <template #button>
                <div class="custom-button"></div>
              </template>
            </van-slider>
          </div>
          <div class="content-btn" @click="adjustmentNextStep">下一步</div>
        </div>
        <div class="dangerBox" v-if="status == 'danger'">
          <div class="list">
            <div class="title">
              <span>风险提示</span>
              <img @click="addDanger" src="./../assets/images/add.png" alt="" />
            </div>
            <ul>
              <li v-for="(item, index) in dataObj.dragAlarmList" :key="index">
                <span> {{ index }}.{{ item.message }}</span>
                <img
                  @click="removeDanger(item, index)"
                  src="./../assets/images/delete.png"
                  alt=""
                />
              </li>
            </ul>
          </div>
          <div class="content-btn" @click="dangerNextStep">下一步</div>
        </div>
        <div></div>
        <div class="angleBox" v-if="status == 'angle'">
          <!-- <p>图纸角度</p> -->
          <div class="rotate">
            <p>图纸角度</p>
            <div class="rotateIcon">
              <img
                @click="shunRotate"
                src="./../assets/images/left.png"
                alt=""
              />
              <img
                @click="niRotate"
                src="./../assets/images/right.png"
                alt=""
              />
            </div>
          </div>
          <div class="content-btn" @click="angleNextStep">下一步</div>
        </div>
      </div>
      <RiskPopup ref="riskRef">
        <p class="risktitle">添加风险点</p>
        <input
          type="text"
          v-model="addRiskValue"
          placeholder="请输入或选择以下风险内容"
        />
        <ul class="riskList">
          <li
            :class="{ checkedAct: riskIndex == index }"
            @click="chooseRisk(item, index)"
            v-for="(item, index) in dataObj.allAlarm"
            :key="index"
          >
            {{ item.message }}
          </li>
        </ul>
        <div class="btnbox">
          <span class="content-btn cancel" @click="riskCancel">取消</span>
          <span class="content-btn" @click="riskConfirm">确定</span>
        </div>
      </RiskPopup>
    </div>
    <div v-if="status == 'drawing'" class="box">
    <!-- <div v-if="statusdrawing" class="box"> -->
      <div class="progressBox" v-if="flag">
        <progress ref="progressRef" value="20" max="100"></progress>
        <div class="textbox">
          <span class="span span1"></span>
          <span class="span span2"></span>
          <span class="span span3"></span>
          <span class="p"> 图纸生成中，请稍等！</span>
          <span class="span span4"></span>
          <span class="span span5"></span>
          <span class="span span6"></span>
        </div>
      </div>
      <div class="finishbox" v-if="!flag">
        <span class="span span1"></span>
        <span class="span span2"></span>
        <span class="span span3"></span>
        <span class="p"> 完成图纸 </span>
        <span class="span span4"></span>
        <span class="span span5"></span>
        <span class="span span6"></span>
      </div>
      <div class="btnbox1">
        <div
          class="button load"
          :class="{ disabledStyle: flag == true }"
          @click="loadPdf"
        >
          下载pdf
        </div>
        <div
          class="button load"
          :class="{ disabledStyle: flag == true }"
          @click="loadDwg"
        >
          下载dwg
        </div>
        <div
          class="button mail"
          :class="{ disabledStyle: flag == true }"
          @click="sendMail"
        >
          发送邮件
        </div>
      </div>
      <VanPopup ref="popupRef" @closeIcon="closeIcon">
        <ul class="popup-content">
          <li class="title">发送邮件</li>
          <li
            class="content-item"
            v-for="(item, index) in nameList"
            :key="index"
            @click="choseName(item.email)"
          >
            {{ item.name }}
            <span
              :class="{ checked: checkedValue.includes(item.email) }"
            ></span>
          </li>
          <div style="display: flex; width: 80%; margin: 0 auto">
            <li class="content-btn cancel" @click="popupCancel">取消</li>
            <li class="content-btn zhubtn" @click="popupConfirm">确定</li>
          </div>
        </ul>
      </VanPopup>
    </div>
    <GantryAngle ref="angleRef" @closeIcon="closeIcon">
      <ul class="popup-content">
        <li class="title">{{ fieldTitle }}</li>
        <li class="desc">请输入{{ fieldTitle }}</li>
        <li class="value">
          <van-field
            @keydown.enter="enterEvent"
            input-align="center"
            :border="false"
            size="large"
            v-model="fieldValue"
            placeholder="未输入"
            ref="focusRef"
            type="number"
          />
        </li>
        <li class="line"></li>
        <li class="ruleText"></li>
        <li class="content-btn" @click="angleConfirm">确定</li>
      </ul>
    </GantryAngle>
  </div>
</template>
<script>
import RiskPopup from './../components/RiskPopup.vue'
import VanPopup from './../components/VanPopup.vue'
import GantryAngle from './../components/GantryAngle.vue'

import { Loaditem, AutoLayout, AddCadTask, SendEmail ,GetTaskStatus,Download} from './../api/2Droom'
import * as PIXI from 'pixi.js'
import { Dialog } from 'vant'
import { Toast } from 'vant'
import baseUrlApi from './../api/baseUrl'
import axios from 'axios'
import{ AES } from "@/plugins/md5.js"
import qs from "qs"
import router from '@/router/index.js'

export default {
  components: {
    RiskPopup,
    VanPopup,
    GantryAngle
  },
  data() {
    return {
      selectLeftRight: 1,
      zhuParams:[],
      zhuformState:0,
      stateCad:0,
      statusdrawing:false,
      baseUrlApi:window.atob(baseUrlApi) ,
      ThirdPartyProjectId: '',
      imgSrc:"",
      kong:[],
      imgList: [],
      textList:[], 
      app: null,
      moveDom: null,
      delDom: null,
      startPoint: null,
      domGrap: {},
      domJson: {},
	  tmpDomJson:{},
      itemJson: {},
      domGrapBJ: {},
      domJsonBJ: {},
      domTxtGrapBJ: {},
      domTxtJsonBJ: {},
      isDrag: false,
      dragFlag: false,
      // dataObj: this.$globle.dataObj,
      dataObj: {},
      status: 'drag',
      planIndex: 0,
      bianjie: [0, 0, 0, 0],
      fanganjson: null,
      toast: null,
      show3D: false,
      addRiskValue: '',
      riskIndex: -1,
      riskItem: {},
      jijiax: 0,
      jijiay: 0,
      jijiaxRight:0,
      jijialength: 0,
      jijiawidth: 0,
      gantryAngle: 0,
      jijiadom: null,
      handPosition: '0',
      jcbjwz: 0,
      fengxianmsg: {},
      domTxtGrap: {},
      planItem: [
        {
          icon: require('./../assets/images/plan1.png'),
          activeIcon: require('./../assets/images/plan-active1.png')
        },
        {
          icon: require('./../assets/images/plan2.png'),
          activeIcon: require('./../assets/images/plan-active2.png')
        },
        {
          icon: require('./../assets/images/plan3.png'),
          activeIcon: require('./../assets/images/plan-active3.png')
        }
      ],
      planItem2: [
        {
          icon: require('./../assets/images/plan1.png'),
          activeIcon: require('./../assets/images/plan-active1.png')
        },
        {
          icon: require('./../assets/images/plan2.png'),
          activeIcon: require('./../assets/images/plan-active2.png')
        }
      ],
      planItem3: [
        {
          icon: require('./../assets/images/plan1.png'),
          activeIcon: require('./../assets/images/plan-active1.png')
        },
      ],
      timer: null,
      checkedValue: [],
      nameList: [],
      emailAddressList: [
        JSON.parse(localStorage.getItem('data')).ThirdPartyProject.pmEmail
      ],
      pdf: '',
      dwg: '',
      pdfToken:'',
      dwgToken:'',
      flag: true,
      taskId: '',
      dbclicktime: '',
      pixiheight: 60,
      beishu: 0.3,
      isweitiao: 0,
      fangjianposition: {
        x: 50,
        y: 60
      },
      zihao: 10,
      OffsetX: 0, //旋转图纸偏移量
      OffsetY: 0,
      fieldValue: '',
      fieldTitle: '',
      handPositionJson: {},
      stateClick:null,
      newField:[],
      bhtongdao: {}
    }
  },
  mounted: function () {
    // this.initPixi();
    this.$bus.$on('change', () => {
      this.dataObj = JSON.parse(localStorage.getItem('data'))
      setTimeout(()=> {
        sessionStorage.setItem('zanshi', JSON.stringify(this.dataObj))
      },500)
      
      this.handleRightPoint()
      this.initPixi()
    })
    this.$bus.$on('open2d', () => {
      this.show3D = false
    })
    window.onbeforeunload = this.refreshSaveData()
    this.demo(this.dataObj)
  },
  created() {
    // 禁止右划退出当前页面
    var startX, startY
    document.addEventListener('touchstart', function (e) {
      startX = e.targetTouches[0].pageX
      startY = e.targetTouches[0].pageY
    })
    document.addEventListener(
      'touchmove',
      function (e) {
        var moveX = e.targetTouches[0].pageX
        var moveY = e.targetTouches[0].pageY
        if (Math.abs(moveX - startX) > Math.abs(moveY - startY)) {
          e.preventDefault()
        }
      },
      { passive: false }
    )

    this.dataObj = JSON.parse(localStorage.getItem('data'))
    this.$bus.$on('back', (num) => {
      clearInterval(this.timer5)
      if (num == 6) {
        this.status = 'angle'
        num = 6
      }
      if (num == 5) {
        this.status = 'danger'
        num = 5
      }
      if (num == 4) {
        for (let i in this.domTxtGrap) {
          this.app.stage.removeChild(this.domTxtGrap[i])
          this.domTxtGrap[i].destroy({
            children: true
          })
          delete this.domTxtGrap[i]
        }
        // this.fengxianmsg = null;
        this.status = 'adjustment'
      }
      if (num == 3) {
        this.status = 'plan'
      }
      if (num == 2) {
        this.status = 'drag'
        this.domJson = {}
        this.initPixi()
        console.log('this.tmpDomJson=================',this.tmpDomJson)
        for(let i in this.tmpDomJson){
          this.tmpDomJson[i].move = true
          this.domJson[i]=JSON.parse(JSON.stringify(this.tmpDomJson[i]));
          this.makeObj(this.tmpDomJson[i])
          
        }
      }
      if (num == 1) {
        this.$router.push('/product')
      }
      localStorage.setItem('currentStatus', this.status)
      clearInterval(this.timer)
      this.timer = null
      clearInterval(this.timer1)
      this.timer1 = null
      this.flag = true
    })
    this.$bus.$on('backHome', () => {
      //后退到Product
      this.status = 'drag'
      this.show3D = false
      this.initPixi()
    })
    this.$bus.$on('statusDrag', () => {
      this.status = 'drag'
    }),
    console.log(this.dataObj, '传递检查床id******')
    Loaditem({
      name: localStorage.getItem('system'),
      mainDeviceId: this.dataObj.examinationCouchId
    }).then((res) => {
      if(res.data.success){
        if (res.data.data != null && res.data.data.aircondition.web2D !== null && res.data.data.aircondition.web2D !== '') {
          this.itemJson = res.data.data
          for (let i in this.itemJson) {
            if(this.itemJson[i] != null && this.itemJson[i].web2DShape != null){
              Download(this.itemJson[i].web2D).then(res=>{
              this.textList.push(this.itemJson[i].web2DShape.name)
              let blob = new Blob([res.data], { type: "image/jpg" });
              var Fr = new FileReader();
              Fr.readAsDataURL(blob);
              Fr.onload = (event) => {
                //这个就是转换为的base64图片地址
                  this.imgList.push(event.target.result)
              };
            })
          }     
          }
          this.dataObj.mainDevice.mainDeviceId = res.data.data.mainDevice
        }else{
          Dialog.alert({
            message: '未获取到空调配电箱图片，请重试!!!'
          })
        }
      }else{
          Dialog.alert({
            message: '未获取到空调配电箱图片，请重试!!!'
          })
        }
    })
    console.log(this.dataObj.ThirdPartyProject, '55555555555555555555555555')
    // 邮件相关处理
    this.dataObj.ThirdPartyProject.subRegionHeaderList.forEach((item) => {
      this.nameList.push(item)
      
    })
    var testEmil = {
        email: 'cao.xufeng@siemens-healthineers.com',
        gId: '',
        name: 'Cao.XuFeng'
      }
      this.nameList.push(testEmil)
  },
  computed: {
    computedAngle: {
      get() {
        return this.gantryAngle > 0 ? this.gantryAngle : -this.gantryAngle
      },
      set(newValue) {
        return newValue
      }
    }
  },
  
  methods: {
    demo(data){
      // let homedataInfo = JSON.parse(sessionStorage.getItem('homeData'))
      let homedataInfo = JSON.parse(sessionStorage.getItem('zanshi'))
      let homedata = JSON.parse(sessionStorage.getItem('homeData'))
      if(homedataInfo.machineRoomForm !== null ){
        data.machineRoomForm.slideDirection = homedataInfo.machineRoomForm.slideDirection
        data.machineRoomForm.patientDirection = homedataInfo.machineRoomForm.patientDirection
        data.machineRoomForm.slideDirection = homedataInfo.machineRoomForm.slideDirection
      }
      if(data.point == 1){
        if(data.controlRoomForm.doorPosition !== 1){
        }else{
          data.controlRoomForm.doorLocation = homedata.machineRoomForm.length - (homedata.controlRoomForm.doorLocation + homedata.controlRoomForm.location + homedata.controlRoomForm.doorWidth)
        }
        
      }
      if(data.zhuForm.length > 0){
        var zhuzi = JSON.parse(sessionStorage.getItem('zhuzi'))
        data.zhuForm.forEach((val,index) => {
          if(data.point === 1){
            val.x = homedataInfo.machineRoomForm.length - zhuzi[index].x - val.height
          }
        })
      }
      
    },
    refreshSaveData() {
      this.handleRightPoint()
      this.$bus.$emit(
        'headValue',
        JSON.parse(localStorage.getItem('data')).ThirdPartyProject
          .shipToPartyCnName,
        localStorage.getItem('system')
      )
      console.log(this.status, 'this.status')
      
      if (
        this.$route.name == '2Droom' &&
        localStorage.getItem('currentStatus')
      ) {
        // this.status = localStorage.getItem('currentStatus')
        this.status = 'drag'
        this.initPixi()
      }
      console.log(this.status, 'this.status')
      if (this.status == 'drag') {
        this.domJson = {}
      }
      if (this.status == 'plan') {
        this.dragNextStep()
      }
      if (
        this.status == 'adjustment' ||
        this.status == 'danger' ||
        this.status == 'angle'
      ) {
        for (let i in this.domGrap) {
          if (i != '机房') {
            this.app.stage.removeChild(this.domGrap[i])
            delete this.domGrap[i]
            delete this.domJson[i]
          }
        }
       this.demo(this.dataObj)
        AutoLayout(this.dataObj).then((res) => {
          if (res.data.success) {
            let processingData = res.data.data
            res.data.data?.checkDimPoints.forEach((val) => {
              this.newField.push(val)
            })
            if (this.dataObj.systemName.indexOf('Symbia') == 0) {
              if(processingData.mainDeviceDrawData['左手位'] !== undefined){
                processingData.drawData.push(
                  processingData.mainDeviceDrawData['左手位']
                )
              }else{
                processingData.drawData.push(processingData.mainDeviceDrawData['诊断床，33°旋转带ICC和AQC'])
              }
              
            } else {
              for (let i in processingData.mainDeviceDrawData) {
                processingData.drawData.push(
                  processingData.mainDeviceDrawData[i]
                )
              }
            }
            processingData.layoutSchemeExports.forEach((ele) => {
              ele.mainDeviceExportData.forEach((item) => {
                if (item.isUse == true) {
                  ele.deviceExportData.push(item)
                }
              })
            })
            this.fanganjson = processingData
            this.choosePlan(localStorage.getItem('planIndex'))
          } else {
            Dialog.alert({ message: res.data.message }).then(() => {
              this.$bus.$emit('goBack')
            })
          }
        })
        // for (let i in this.domTxtGrap) {
        //     this.app.stage.removeChild(this.domTxtGrap[i]);
        //     delete this.domTxtGrap[i];
        //   }
      }
      // if (this.status == 'drawing') {
      // }
    },
    // 二进制转base64
     getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    var dataURL = canvas.toDataURL("image/" + ext);
    return dataURL;
    },
    open3d() {
      this.show3D = true
      this.$bus.$emit('open3d', this.dataObj)
    },
    // 定位点在右计算和控制室门方位为左右时
    handleRightPoint() {
    console.log(this.dataObj.machineRoomForm, 'this.dataObj.machineRoomForm**************')
      if (this.dataObj.point == 1) {
        this.dataObj.controlRoomForm.location =
          this.dataObj.machineRoomForm.length -
          this.dataObj.controlRoomForm.length -
          this.dataObj.controlRoomForm.location
        if (
          this.dataObj.machineRoomForm.patientPosition == 0 ||
          this.dataObj.machineRoomForm.patientPosition == 1
        ) {
          this.dataObj.machineRoomForm.patientLocation =
            this.dataObj.machineRoomForm.length -
            Number(this.dataObj.machineRoomForm.patientLocation) -
            Number(this.dataObj.machineRoomForm.patientWidth)
        }
        this.dataObj.machineRoomForm.technicianLocation =
          this.dataObj.machineRoomForm.length -
          Number(this.dataObj.machineRoomForm.technicianLocation) -
          Number(this.dataObj.machineRoomForm.technicianWidth)
        this.dataObj.controlRoomForm.windowLocation =
          this.dataObj.machineRoomForm.length -
          this.dataObj.controlRoomForm.windowLocation -
          this.dataObj.controlRoomForm.windowWidth
        if (this.dataObj.controlRoomForm.doorPosition == 1) {
          this.dataObj.controlRoomForm.doorLocation =
            this.dataObj.machineRoomForm.length -
            Number(this.dataObj.controlRoomForm.doorLocation) -
            Number(this.dataObj.controlRoomForm.doorWidth)
        }
        // 柱子
        this.dataObj.zhuForm.forEach((item) => {
          item.x = this.dataObj.machineRoomForm.length - item.x
        })
      }
      if (typeof this.dataObj.machineRoomForm.patientLocation == 'string') {
        this.dataObj.machineRoomForm.patientWidth = 0
      }
      if (typeof this.dataObj.machineRoomForm.technicianLocation == 'string') {
        this.dataObj.machineRoomForm.technicianWidth = 0
      }
      if (typeof this.dataObj.controlRoomForm.doorLocation == 'string') {
        this.dataObj.controlRoomForm.doorWidth = 0
      }
      if (
        this.dataObj.controlRoomForm.doorPosition == 2 ||
        this.dataObj.controlRoomForm.doorPosition == 3
      ) {
        this.dataObj.controlRoomForm.doorLocation =
          Number(this.dataObj.controlRoomForm.doorLocation) +
          Number(this.dataObj.machineRoomForm.width) +
          Number(this.dataObj.machineRoomForm.wallThickness)
      }
    },
    objFn(obj, objIndex, objs) {
      // if(obj !== undefined){
        console.log(objs, '[objIndex].name****')
        console.log(objs[objIndex].name, '[objIndex].name****')
        return objs[objIndex].name == '检查床'
      // }
    },
    radioChange() {
      
      // var params = JSON.parse(sessionStorage.getItem('fanganjson')).drawData
      let index = this.fanganjson.drawData.findIndex(this.objFn)
      if (this.handPosition == 0) {
        console.log(this.fanganjson.mainDeviceDrawData['左手位'], 'this.fanganjson.mainDeviceDrawData左手位')
        if(this.fanganjson.mainDeviceDrawData['左手位'] !== undefined){
          this.fanganjson.drawData[index] = this.fanganjson.mainDeviceDrawData['左手位']
          this.selectLeftRight = 1
        }else if(this.fanganjson.mainDeviceDrawData['诊断床，33°旋转带ICC和AQC'] !== undefined){
          this.fanganjson.drawData[index] = this.fanganjson.mainDeviceDrawData['诊断床，33°旋转带ICC和AQC']
          this.selectLeftRight = 1
        }
        // this.fanganjson.drawData[index] = this.fanganjson.mainDeviceDrawData[
        //   '左手位'
        // ]
      } else {
        if(this.fanganjson.mainDeviceDrawData['右手位'] !== undefined){
          this.fanganjson.drawData[index] = this.fanganjson.mainDeviceDrawData['右手位']
          this.selectLeftRight = 2
        }else if(this.fanganjson.mainDeviceDrawData['诊断床，33°旋转不带ICC和AQC'] !== undefined){
          this.fanganjson.drawData[index] = this.fanganjson.mainDeviceDrawData['诊断床，33°旋转不带ICC和AQC']
          this.selectLeftRight = 2
        }
        // this.fanganjson.drawData[index] = this.fanganjson.mainDeviceDrawData[
        //   '右手位'
        // ]
      }
      console.log(index)
      console.log(this.fanganjson.drawData)

      // this.choosePlan(0)
      this.choosePlan(this.planIndex)
    },
    dragDown(name) {
      console.log(name, '名字')
      if(name === '空调'){
        name = 'aircondition'
      }
      if(name === '现场配电箱'){
        name = 'powerBox'
      }
      if(name === '除湿机'){
        name = 'dehumidifier'
      }
      console.log(name, '空调或配电箱的名字')
      var name2 = name
      if (name == 'aircondition') name2 = 'aircondition' + '_' + Math.random()
      if (this.domJson[name2] != undefined) return
      this.domJson[name2] = JSON.parse(
        JSON.stringify(this.itemJson[name].web2DShape)
      )
      this.domJson[name2].name = name2
      this.domJson[name2].rotation = undefined
      this.makeObj(this.domJson[name2])
      // this.domJson[name]=this.itemJson[name].web2DShape
      // this.makeObj(this.itemJson[name].web2DShape);
    },
    initPixi() {
      this.jcbjwz = this.dataObj.point
      this.dataObj.controlRoomForm.length = Number(
        this.dataObj.controlRoomForm.length
      )
      this.dataObj.controlRoomForm.width = Number(
        this.dataObj.controlRoomForm.width
      )
      this.dataObj.controlRoomForm.doorLocation = Number(
        this.dataObj.controlRoomForm.doorLocation
      )
      this.dataObj.controlRoomForm.doorWidth = Number(
        this.dataObj.controlRoomForm.doorWidth
      )
      this.dataObj.controlRoomForm.location = Number(
        this.dataObj.controlRoomForm.location
      )
      var os = (function () {
        var ua = navigator.userAgent,
          isWindowsPhone = /(?:Windows Phone)/.test(ua),
          isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
          isAndroid = /(?:Android)/.test(ua),
          isFireFox = /(?:Firefox)/.test(ua),
          isChrome = /(?:Chrome|CriOS)/.test(ua),
          isTablet =
            /(?:iPad|PlayBook)/.test(ua) ||
            (isAndroid && !/(?:Mobile)/.test(ua)) ||
            (isFireFox && /(?:Tablet)/.test(ua)),
          isPhone = /(?:iPhone)/.test(ua) && !isTablet,
          isPc = !isPhone && !isAndroid && !isSymbian
        return {
          isTablet: isTablet,
          isPhone: isPhone,
          isAndroid: isAndroid,
          isPc: isPc
        }
      })()

      if (os.isAndroid || os.isPhone) {
        this.beishu = 0.3
        this.fangjianposition = {
          x:
            document.body.clientWidth / 2 -
            ((this.dataObj.machineRoomForm.length +
              this.dataObj.machineRoomForm.wallThickness * 2 +
              this.dataObj.machineRoomForm.decorateThickness * 2) *
              this.beishu) /
              2,
          y: 60
        }
        this.zihao = 10
      } else {
        this.beishu = 0.5
        this.fangjianposition = {
          x:
            document.body.clientWidth / 2 -
            ((this.dataObj.machineRoomForm.length +
              this.dataObj.machineRoomForm.wallThickness * 2 +
              this.dataObj.machineRoomForm.decorateThickness * 2) *
              this.beishu) /
              2,
          y: 120
        }
        this.zihao = 18
      }
      //1344*0.5是pid 1180的最佳高度
      let cavansheight = 1344 * this.beishu + 1
      let pixiheightmin =
        (((57 * 1180) / document.body.clientHeight) * this.beishu) / 0.5
      if (
        this.dataObj.machineRoomForm.width +
          this.dataObj.controlRoomForm.width +
          this.dataObj.machineRoomForm.wallThickness * 3 +
          this.dataObj.machineRoomForm.decorateThickness * 2 >
        922
      ) {
        this.pixiheight =
          (pixiheightmin *
            (this.dataObj.machineRoomForm.width +
              this.dataObj.controlRoomForm.width +
              this.dataObj.machineRoomForm.wallThickness * 3 +
              this.dataObj.machineRoomForm.decorateThickness * 2)) /
          922
        cavansheight =
          (cavansheight *
            (this.dataObj.machineRoomForm.width +
              this.dataObj.controlRoomForm.width +
              this.dataObj.machineRoomForm.wallThickness * 3 +
              this.dataObj.machineRoomForm.decorateThickness * 2)) /
            922 +
          1
      } else {
        this.pixiheight = pixiheightmin
      }
      console.log(this.dataObj)
      if (this.app != null) {
        // this.app.stage.removeChildren()
        let removedChilds = this.app.stage.removeChildren(
          0,
          this.app.stage.children.length
        )
        removedChilds.forEach((c) => {
          c.destroy({
            children: true
          })
        })
        // this.app.stage.removeStageReference()
        // this.app.view.style.height=cavansheight
        if (document.getElementById('pixi')) {
          this.app.renderer.resize(
            document.getElementById('pixi').offsetWidth,
            cavansheight
          )
        }
      } else {
        this.app = new PIXI.Application({
          width: document.getElementById('pixi').offsetWidth,
          height: cavansheight,
          backgroundColor: 0xffffff,
          antialias: true,
          transparent: false,
          resolution: 1
        })
        this.app.stage.sortableChildren = true
        document.getElementById('pixi').appendChild(this.app.view)

        this.app.renderer.plugins.interaction.on('touchstart', (event) => {
          console.log(event, '触摸到屏幕===')
      console.log(this.bianjie, 'this.bianjie****')
          this.dragFlag = true
          this.startPoint = {
            x: event.data.global.x,
            y: event.data.global.y
          }
        })
        this.app.renderer.plugins.interaction.on('touchmove', (event) => {
          // let coord = JSON.stringify(event)
          // console.log(event, 'event')
          // sessionStorage.setItem('coord',coord)
          if (this.dragFlag) {
            this.isDrag = true
            // console.log(this.startPoint.x, 'this.startPoint.x')
            // console.log(this.startPoint.y, 'this.startPoint.y')
            // console.log(event.data.global.x, 'event.data.global.x')
            // console.log(event.data.global.y, 'event.data.global.y')
            const dx = event.data.global.x - this.startPoint.x
            const dy = event.data.global.y - this.startPoint.y
            console.log()
            if (this.moveDom != null) {
              if (this.status == 'drag') {
                var jsonname = this.moveDom.split('_')[0]
                let rongcha = 2
                let objbianjuw =
                  (this.domJson[this.moveDom].length / 2) * this.beishu
                let objbianjuh =
                  (this.domJson[this.moveDom].width / 2) * this.beishu
                if (jsonname == 'aircondition') {
                  this.dataObj[jsonname][this.moveDom] = {
                    x:
                      Math.round(
                        ((this.domGrap[this.moveDom].x - this.bianjie[0]) *
                          100) /
                          3
                      ) / 10,
                    y:
                      Math.round(
                        ((this.domGrap[this.moveDom].y - this.bianjie[1]) *
                          100) /
                          3
                      ) / 10,
                    r: (this.domGrap[this.moveDom].rotation * 180) / Math.PI
                  }
                } else {
                  this.dataObj[this.moveDom] = {
                    x:
                      Math.round(
                        ((this.domGrap[this.moveDom].x - this.bianjie[0]) *
                          100) /
                          3
                      ) / 10,
                    y:
                      Math.round(
                        ((this.domGrap[this.moveDom].y - this.bianjie[1]) *
                          100) /
                          3
                      ) / 10,
                    r: (this.domGrap[this.moveDom].rotation * 180) / Math.PI
                  }
                }

                
                //左右
                if (
                  this.domGrap[this.moveDom].y + dy >
                    this.bianjie[1] + objbianjuw &&
                  this.domGrap[this.moveDom].y + dy <
                    this.bianjie[3] - objbianjuw
                ) {
                  //上
                  if (
                    this.domGrap[this.moveDom].x + dx >
                      this.bianjie[0] + objbianjuh - rongcha &&
                    this.domGrap[this.moveDom].x + dx <
                      this.bianjie[0] + objbianjuh + rongcha
                  ) {
                    console.log('上超左边')
                    // 超左边
                    this.domGrap[this.moveDom].x = this.bianjie[0] + objbianjuh
                  } else if (
                    this.domGrap[this.moveDom].x + dx >
                      this.bianjie[2] - objbianjuh - rongcha &&
                    this.domGrap[this.moveDom].x + dx >
                      this.bianjie[2] - objbianjuh + rongcha
                  ) {
                    console.log('上超右边')
                    //超右边
                    this.domGrap[this.moveDom].x = this.bianjie[2] - objbianjuh
                  }

                  if (
                    this.domGrap[this.moveDom].x ==
                      this.bianjie[0] + objbianjuh ||
                    this.domGrap[this.moveDom].x == this.bianjie[2] - objbianjuh
                  )
                    this.domGrap[this.moveDom].y += dy
                } else if (
                  this.domGrap[this.moveDom].y + dy >
                    this.bianjie[5] + objbianjuw &&
                  this.domGrap[this.moveDom].y + dy <
                    this.bianjie[7] - objbianjuw
                ) {
                  //下
                  if (
                    this.domGrap[this.moveDom].x + dx >
                      this.bianjie[4] + objbianjuh - rongcha &&
                    this.domGrap[this.moveDom].x + dx <
                      this.bianjie[4] + objbianjuh + rongcha
                  ) {
                    console.log('下超左边')
                    //超左边
                    this.domGrap[this.moveDom].x = this.bianjie[4] + objbianjuh
                  } else if (
                    this.domGrap[this.moveDom].x + dx >
                      this.bianjie[6] - objbianjuh - rongcha &&
                    this.domGrap[this.moveDom].x + dx >
                      this.bianjie[6] - objbianjuh + rongcha
                  ) {
                    console.log('下超右边')
                    //超右边
                    this.domGrap[this.moveDom].x = this.bianjie[6] - objbianjuh
                  }
                  if (
                    this.domGrap[this.moveDom].x ==
                      this.bianjie[4] + objbianjuh ||
                    this.domGrap[this.moveDom].x == this.bianjie[6] - objbianjuh
                  )
                    this.domGrap[this.moveDom].y += dy
                }
                //上上
                if (
                  this.domGrap[this.moveDom].x + dx >
                    this.bianjie[0] + objbianjuw &&
                  this.domGrap[this.moveDom].x + dx <
                    this.bianjie[2] - objbianjuw &&
                  this.domGrap[this.moveDom].y <
                    this.bianjie[1] + objbianjuh + rongcha
                ) {
                    console.log('上上')
                  this.domGrap[this.moveDom].y = this.bianjie[1] + objbianjuh
                  this.domGrap[this.moveDom].x += dx
                }
                //下下
                else if (
                  this.domGrap[this.moveDom].x + dx >
                    this.bianjie[4] + objbianjuw &&
                  this.domGrap[this.moveDom].x + dx <
                    this.bianjie[6] - objbianjuw &&
                  this.domGrap[this.moveDom].y >
                    this.bianjie[7] - objbianjuh - rongcha
                ) {
                    console.log('下下')
                  this.domGrap[this.moveDom].y = this.bianjie[7] - objbianjuh
                  this.domGrap[this.moveDom].x += dx
                }

                
                // // 机房下面墙外
                // if(sessionStorage.getItem('isHaveKong') !== 'true'){
                // if(this.domGrap[this.moveDom].y + dy >
                //     this.bianjie[5] + objbianjuw &&
                //   this.domGrap[this.moveDom].y + dy <
                //     this.bianjie[7] - objbianjuw){
                //       if (
                //         this.domGrap[this.moveDom].x ==
                //           this.bianjie[4] + objbianjuh ||                
                //         this.domGrap[this.moveDom].x == this.bianjie[6] - objbianjuh
                //       ){
                //         console.log('7777777')
                //         this.domGrap[this.moveDom].x += dx
                //       }
                //     }
                // }

                //上下
                else if (
                  this.domGrap[this.moveDom].x + dx >
                    this.bianjie[0] + objbianjuw &&
                  this.domGrap[this.moveDom].x + dx <
                    this.bianjie[2] - objbianjuw &&
                  this.domGrap[this.moveDom].y == this.bianjie[3] - objbianjuh
                ) {
                    console.log('上下')
                  this.domGrap[this.moveDom].x += dx
                }
                //下上
                else if (
                  this.domGrap[this.moveDom].x + dx >
                    this.bianjie[4] + objbianjuw &&
                  this.domGrap[this.moveDom].x + dx <
                    this.bianjie[6] - objbianjuw &&
                  this.domGrap[this.moveDom].y == this.bianjie[5] + objbianjuh
                ) {
                    console.log('下上')
                  this.domGrap[this.moveDom].x += dx
                }
               


                if (sessionStorage.getItem('isHaveKong') == 'true') {
                  if (
                    this.domGrap[this.moveDom].y + dy >
                      this.bianjie[3] - objbianjuw &&
                    this.domGrap[this.moveDom].y + dy < this.bianjie[5] &&
                    dy > 0
                  ) {
                    if (
                      this.domGrap[this.moveDom].x ==
                        this.bianjie[0] + objbianjuh &&
                      dx <= 0
                    ) {
                      this.domGrap[this.moveDom].rotation = Math.PI / 2
                      this.domGrap[this.moveDom].x =
                        this.bianjie[4] + objbianjuh
                      this.domGrap[this.moveDom].y =
                        this.bianjie[5] + objbianjuw
                    } else if (
                      this.domGrap[this.moveDom].x ==
                        this.bianjie[2] - objbianjuh &&
                      dx >= 0
                    ) {
                      this.domGrap[this.moveDom].rotation = (Math.PI / 2) * 3
                      this.domGrap[this.moveDom].x =
                        this.bianjie[6] - objbianjuh
                      this.domGrap[this.moveDom].y =
                        this.bianjie[5] + objbianjuw
                    }
                  } else if (
                    this.domGrap[this.moveDom].y + dy <
                      this.bianjie[5] + objbianjuw &&
                    this.domGrap[this.moveDom].y + dy > this.bianjie[3] &&
                    dy < 0
                  ) {
                    if (
                      this.domGrap[this.moveDom].x ==
                        this.bianjie[4] + objbianjuh &&
                      dx <= 0
                    ) {
                      this.domGrap[this.moveDom].rotation = Math.PI / 2
                      this.domGrap[this.moveDom].x =
                        this.bianjie[0] + objbianjuh
                      this.domGrap[this.moveDom].y =
                        this.bianjie[3] - objbianjuw
                    } else if (
                      this.domGrap[this.moveDom].x ==
                        this.bianjie[6] - objbianjuh &&
                      dx >= 0
                    ) {
                      this.domGrap[this.moveDom].rotation = (Math.PI / 2) * 3
                      this.domGrap[this.moveDom].x =
                        this.bianjie[2] - objbianjuh
                      this.domGrap[this.moveDom].y =
                        this.bianjie[3] - objbianjuw
                    }
                  }
                }

                var rongcha2 = 2
                //上-左上-上
                if (
                  this.domGrap[this.moveDom].y <=
                    this.bianjie[1] + objbianjuw + rongcha2 &&
                  this.domGrap[this.moveDom].x ==
                    this.bianjie[0] + objbianjuh &&
                  dy < 0
                ) {
                    console.log('上-左上-上')
                  this.domGrap[this.moveDom].rotation = Math.PI
                  this.domGrap[this.moveDom].x = this.bianjie[0] + objbianjuw
                  this.domGrap[this.moveDom].y = this.bianjie[1] + objbianjuh
                }
                //上-左上-左
                else if (
                  this.domGrap[this.moveDom].x <=
                    this.bianjie[0] + objbianjuw + rongcha2 &&
                  this.domGrap[this.moveDom].y ==
                    this.bianjie[1] + objbianjuh &&
                  dx < 0
                ) {
                    console.log('上-左上-左')
                  this.domGrap[this.moveDom].rotation = Math.PI / 2
                  this.domGrap[this.moveDom].x = this.bianjie[0] + objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[1] + objbianjuw
                }
                //上-右上-上
                else if (
                  this.domGrap[this.moveDom].y <=
                    this.bianjie[1] + objbianjuw + rongcha2 &&
                  this.domGrap[this.moveDom].x ==
                    this.bianjie[2] - objbianjuh &&
                  dy < 0
                ) {
                    console.log('上-右上-上')
                  this.domGrap[this.moveDom].rotation = Math.PI
                  this.domGrap[this.moveDom].x = this.bianjie[2] - objbianjuw
                  this.domGrap[this.moveDom].y = this.bianjie[1] + objbianjuh
                }
                //上-右上-右
                else if (
                  this.domGrap[this.moveDom].x >=
                    this.bianjie[2] - objbianjuw - rongcha2 &&
                  this.domGrap[this.moveDom].y ==
                    this.bianjie[1] + objbianjuh &&
                  dx > 0
                ) {
                    console.log('上-右上-右')
                  this.domGrap[this.moveDom].rotation = (Math.PI / 2) * 3
                  this.domGrap[this.moveDom].x = this.bianjie[2] - objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[1] + objbianjuw
                }
                //下-左下-下
                else if (
                  this.domGrap[this.moveDom].y >=
                    this.bianjie[7] - objbianjuw - rongcha2 &&
                  this.domGrap[this.moveDom].x ==
                    this.bianjie[4] + objbianjuh &&
                  dy > 0
                  && sessionStorage.getItem('isHaveKong') == 'true'
                ) {
                    console.log('下-左下-下')
                  this.domGrap[this.moveDom].rotation = 0
                  this.domGrap[this.moveDom].x = this.bianjie[4] + objbianjuw
                  this.domGrap[this.moveDom].y = this.bianjie[7] - objbianjuh
                }
                //下-左下-左
                else if (
                  this.domGrap[this.moveDom].x <=
                    this.bianjie[4] + objbianjuw + rongcha2 &&
                  this.domGrap[this.moveDom].y ==
                    this.bianjie[7] - objbianjuh &&
                  dx < 0
                ) {
                    console.log('下-左下-左')
                  this.domGrap[this.moveDom].rotation = Math.PI / 2
                  this.domGrap[this.moveDom].x = this.bianjie[4] + objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[7] - objbianjuw
                }
                //下-右下-下
                else if (
                  this.domGrap[this.moveDom].y >=
                    this.bianjie[7] - objbianjuw - rongcha2 &&
                  this.domGrap[this.moveDom].x ==
                    this.bianjie[6] - objbianjuh &&
                  dy > 0
                  && sessionStorage.getItem('isHaveKong') == 'true'
                ) {
                    console.log('下-右下-下')
                  this.domGrap[this.moveDom].rotation = 0
                  this.domGrap[this.moveDom].x = this.bianjie[6] - objbianjuw
                  this.domGrap[this.moveDom].y = this.bianjie[7] - objbianjuh
                }
                //下-右下-右
                else if (
                  this.domGrap[this.moveDom].x >=
                    this.bianjie[6] - objbianjuw - rongcha2 &&
                  this.domGrap[this.moveDom].y ==
                    this.bianjie[7] - objbianjuh &&
                  dx > 0
                  && sessionStorage.getItem('isHaveKong') == 'true'
                ) {
                    console.log('下-右下-右')
                  this.domGrap[this.moveDom].rotation = (Math.PI / 2) * 3
                  this.domGrap[this.moveDom].x = this.bianjie[6] - objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[7] - objbianjuw
                }
                //上-左下-下
                else if (
                  this.domGrap[this.moveDom].y >=
                    this.bianjie[3] - objbianjuw - rongcha2 * 2 &&
                  this.domGrap[this.moveDom].y <=
                    this.bianjie[3] - objbianjuw &&
                  this.domGrap[this.moveDom].x ==
                    this.bianjie[0] + objbianjuh &&
                  dx > 0
                ) {
                    console.log('上-左下-下')
                  this.domGrap[this.moveDom].rotation = 0
                  this.domGrap[this.moveDom].x = this.bianjie[0] + objbianjuw
                  this.domGrap[this.moveDom].y = this.bianjie[3] - objbianjuh
                }
                //上-左下-左
                else if (
                  this.domGrap[this.moveDom].x <=
                    this.bianjie[0] + objbianjuw + rongcha2 &&
                  this.domGrap[this.moveDom].y ==
                    this.bianjie[3] - objbianjuh &&
                  dx < 0
                ) {
                    console.log('上-左下-左')
                  this.domGrap[this.moveDom].rotation = Math.PI / 2
                  this.domGrap[this.moveDom].x = this.bianjie[0] + objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[3] - objbianjuw
                }
                //上-右下-下
                else if (
                  this.domGrap[this.moveDom].y >=
                    this.bianjie[3] - objbianjuw - rongcha2 * 2 &&
                  this.domGrap[this.moveDom].y <=
                    this.bianjie[3] - objbianjuw &&
                  this.domGrap[this.moveDom].x ==
                    this.bianjie[2] - objbianjuh &&
                  dx < 0
                ) {
                    console.log('上-右下-下')
                  this.domGrap[this.moveDom].rotation = 0
                  this.domGrap[this.moveDom].x = this.bianjie[2] - objbianjuw
                  this.domGrap[this.moveDom].y = this.bianjie[3] - objbianjuh
                }
                //上-右下-右
                else if (
                  this.domGrap[this.moveDom].x >=
                    this.bianjie[2] - objbianjuw - rongcha2 &&
                  this.domGrap[this.moveDom].y ==
                    this.bianjie[3] - objbianjuh &&
                  dx > 0
                ) {
                    console.log('上-右下-右')
                  this.domGrap[this.moveDom].rotation = (Math.PI / 2) * 3
                  this.domGrap[this.moveDom].x = this.bianjie[2] - objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[3] - objbianjuw
                }
                // //下-左上-上
                // else if (
                //   this.domGrap[this.moveDom].y <=
                //     this.bianjie[5] + objbianjuw + rongcha2 * 2 &&
                //   this.domGrap[this.moveDom].y >=
                //     this.bianjie[5] + objbianjuw &&
                //   this.domGrap[this.moveDom].x ==
                //     this.bianjie[4] + objbianjuh &&
                //   dx > 0
                // ) {
                //     console.log('下-左上-上')
                //   this.domGrap[this.moveDom].rotation = Math.PI
                //   this.domGrap[this.moveDom].x = this.bianjie[4] + objbianjuw
                //   this.domGrap[this.moveDom].y = this.bianjie[5] + objbianjuh
                // }
                // //下-左上-左
                // else if (
                //   this.domGrap[this.moveDom].x <=
                //     this.bianjie[4] + objbianjuw + rongcha2 &&
                //   this.domGrap[this.moveDom].y ==
                //     this.bianjie[5] + objbianjuh &&
                //   dx < 0
                // ) {
                //     console.log('下-左上-左')
                //   this.domGrap[this.moveDom].rotation = Math.PI / 2
                //   this.domGrap[this.moveDom].x = this.bianjie[4] + objbianjuh
                //   this.domGrap[this.moveDom].y = this.bianjie[5] + objbianjuw
                // }
                //下-右上-上
                else if (
                  this.domGrap[this.moveDom].y <=
                    this.bianjie[5] + objbianjuw + rongcha2 * 2 &&
                  this.domGrap[this.moveDom].y >=
                    this.bianjie[5] + objbianjuw &&
                  this.domGrap[this.moveDom].x ==
                    this.bianjie[6] - objbianjuh &&
                  dx < 0
                ) {
                    console.log('下-右上-上')
                  this.domGrap[this.moveDom].rotation = Math.PI
                  this.domGrap[this.moveDom].x = this.bianjie[6] - objbianjuw
                  this.domGrap[this.moveDom].y = this.bianjie[5] + objbianjuh
                }
                //下-右上-右
                else if (
                  this.domGrap[this.moveDom].x >=
                    this.bianjie[6] - objbianjuw - rongcha2 &&
                  this.domGrap[this.moveDom].y ==
                    this.bianjie[5] + objbianjuh &&
                  dx > 0
                ) {
                    console.log('下-右上-右')
                  this.domGrap[this.moveDom].rotation = (Math.PI / 2) * 3
                  this.domGrap[this.moveDom].x = this.bianjie[6] - objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[5] + objbianjuw
                }


                 /**
                 * start
                 */

                 if(sessionStorage.getItem('isHaveKong') != 'true'){
                  if (
                    this.domGrap[this.moveDom].y + dy >
                      this.bianjie[3] - objbianjuw &&
                    this.domGrap[this.moveDom].y + dy < this.bianjie[5] &&
                    dy > 0
                  ){
                    if (
                      this.domGrap[this.moveDom].x ==
                        this.bianjie[0] + objbianjuh &&
                      dx <= 0
                    ){
                      this.domGrap[this.moveDom].rotation = Math.PI
                      this.domGrap[this.moveDom].x = this.bianjie[0] + objbianjuh
                      if (jsonname == 'aircondition') {
                        let testDat = this.bianjie[3] + this.dataObj.machineRoomForm.wallThickness * this.beishu
                        this.domGrap[this.moveDom].y = testDat + objbianjuw - rongcha
                      } else {
                        this.domGrap[this.moveDom].y = this.bianjie[3] + objbianjuw
                      }
                    }
                  }
                  if(this.domGrap[this.moveDom].y >= this.bianjie[3]){
                    this.domGrap[this.moveDom].x += dx
                    if(this.domGrap[this.moveDom].x >  this.bianjie[2]){
                      this.domGrap[this.moveDom].x = this.bianjie[2] - objbianjuh
                    }
                    if(this.domGrap[this.moveDom].x < this.bianjie[0]){
                      // this.domGrap[this.moveDom].x = this.bianjie[0] + objbianjuh
                      
                  this.domGrap[this.moveDom].rotation = Math.PI / 2
                  this.domGrap[this.moveDom].x = this.bianjie[0] + objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[1] + objbianjuw
                    }
                    if(this.domGrap[this.moveDom].y < this.bianjie[3]){
                      this.domGrap[this.moveDom].y = this.bianjie[3] - objbianjuw
                    }
                  }
                 
                }else{
                  //下-左上-上
                 if (
                  this.domGrap[this.moveDom].y <=
                    this.bianjie[5] + objbianjuw + rongcha2 * 2 &&
                  this.domGrap[this.moveDom].y >=
                    this.bianjie[5] + objbianjuw &&
                  this.domGrap[this.moveDom].x ==
                    this.bianjie[4] + objbianjuh &&
                  dx > 0
                ) {
                    console.log('下-左上-上')
                  this.domGrap[this.moveDom].rotation = Math.PI
                  this.domGrap[this.moveDom].x = this.bianjie[4] + objbianjuw
                  this.domGrap[this.moveDom].y = this.bianjie[5] + objbianjuh
                }
                //下-左上-左
                else if (
                  this.domGrap[this.moveDom].x <=
                    this.bianjie[4] + objbianjuw + rongcha2 &&
                  this.domGrap[this.moveDom].y ==
                    this.bianjie[5] + objbianjuh &&
                  dx < 0
                ) {
                    console.log('下-左上-左')
                  this.domGrap[this.moveDom].rotation = Math.PI / 2
                  this.domGrap[this.moveDom].x = this.bianjie[4] + objbianjuh
                  this.domGrap[this.moveDom].y = this.bianjie[5] + objbianjuw
                }
                }

                /**
                 * end
                 */


                this.domJson[this.moveDom].position.x = this.domGrap[
                  this.moveDom
                ].x
                this.domJson[this.moveDom].position.y = this.domGrap[
                  this.moveDom
                ].y
                this.domJson[this.moveDom].rotation =
                  (this.domGrap[this.moveDom].rotation * 180) / Math.PI
                if (jsonname == 'aircondition') {
                  this.dataObj['aircondition'][this.moveDom].x =
                    Math.round(
                      ((this.domGrap[this.moveDom].x - this.bianjie[0]) * 100) /
                        3
                    ) / 10
                  this.dataObj['aircondition'][this.moveDom].y =
                    Math.round(
                      ((this.domGrap[this.moveDom].y - this.bianjie[1]) * 100) /
                        3
                    ) / 10
                  this.dataObj['aircondition'][this.moveDom].r =
                    (this.domGrap[this.moveDom].rotation * 180) / Math.PI
                } else {
                  this.dataObj[this.moveDom].x =
                    Math.round(
                      ((this.domGrap[this.moveDom].x - this.bianjie[0]) * 100) /
                        3
                    ) / 10
                  this.dataObj[this.moveDom].y =
                    Math.round(
                      ((this.domGrap[this.moveDom].y - this.bianjie[1]) * 100) /
                        3
                    ) / 10
                  this.dataObj[this.moveDom].r =
                    (this.domGrap[this.moveDom].rotation * 180) / Math.PI
                }
              }
            }
            if (this.status == 'danger') {
              if (this.moveDom == parseInt(this.moveDom)) {
                this.domTxtGrap[this.moveDom].x += dx
                this.domTxtGrap[this.moveDom].y += dy
                for (let x in this.fengxianmsg) {
                  if (this.fengxianmsg[x] == this.moveDom) {
                    for (let i in this.dataObj.dragAlarmList) {
                      let v = this.dataObj.dragAlarmList[i]
                      if (v.message == x) {
                        v.point.x =
                          Math.round(
                            ((this.domTxtGrap[this.moveDom].x-this.bianjie[0]) / this.beishu) * 10
                          ) / 10
                        v.point.y =
                          Math.round(
                            ((this.domTxtGrap[this.moveDom].y-this.bianjie[1]) / this.beishu) * 10
                          ) / 10
                        break
                      }
                    }
                  }
                }
              }
            }
            this.startPoint = {
              x: event.data.global.x,
              y: event.data.global.y
            }
          }
        })
        this.app.renderer.plugins.interaction.on('touchend', (event) => {
          console.log(event, '移动后松开鼠标----------')
          this.moveDom = null
          this.dragFlag = false
          this.isDrag = false
          
          
          
        })
      }
      this.initDrag()
    },
    initDrag() {
      console.log('----------init')
      this.domTxtJsonBJ = {}
      this.domJsonBJ = {}
      this.dataObj['aircondition'] = {}
      this.domJson = {}
      this.domJson['机房'] = {
        name: '机房',
        position: this.fangjianposition,
        rotation: 0,
        move: false,
        styles: [
          {
            weight: 1,
            color: '0xe40506',
            alpha: 1
          },
          {
            weight: 1,
            color: '0x469606',
            alpha: 1
          },
          {
            weight: 1,
            color: '0xFFFFFF',
            alpha: 1
          },
          {
            weight: 1,
            color: '0x5a9218',
            alpha: 1
          }
        ],
        child: null
      }
      var qianghou =
        this.dataObj.machineRoomForm.wallThickness +
        Number(Number(this.dataObj.machineRoomForm.decorateThickness))
      //走到
      // this.dataObj.machineRoomForm.patientPosition=2

      if (this.dataObj.machineRoomForm.patientPosition == 0) {
        if (Number(this.dataObj.machineRoomForm.corridorWidth)) {
          var zoudao = [
            {
              style: 0,
              line: {
                x: 0,
                y: -Number(this.dataObj.machineRoomForm.corridorWidth),
                x2:
                  this.dataObj.machineRoomForm.length +
                  this.dataObj.machineRoomForm.wallThickness * 2 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 2,
                y2: -Number(this.dataObj.machineRoomForm.corridorWidth)
              }
            },
            {
              style: 0,
              line: {
                x: 0,
                y: -(
                  Number(this.dataObj.machineRoomForm.corridorWidth) +
                  this.dataObj.machineRoomForm.wallThickness +
                  Number(this.dataObj.machineRoomForm.decorateThickness)
                ),
                x2:
                  this.dataObj.machineRoomForm.length +
                  this.dataObj.machineRoomForm.wallThickness * 2 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 2,
                y2: -(
                  Number(this.dataObj.machineRoomForm.corridorWidth) +
                  this.dataObj.machineRoomForm.wallThickness +
                  Number(this.dataObj.machineRoomForm.decorateThickness)
                )
              }
            }
          ]
          this.domJson['机房'].position.y = this.domJson['机房'].position.y + 10
          // this.domJson['机房'].position.x = this.domJson['机房'].position.x + 30
        }
        if (
          this.dataObj.machineRoomForm.patientLocation != '' ||
          Number(this.dataObj.machineRoomForm.patientWidth) != 0
        ) {
          var pingyimenLocation = null
          console.log(this.dataObj.machineRoomForm, 'this.dataObj.machineRoomForm')
          if(this.dataObj.machineRoomForm.slideDirection === 1){
            this.pingyimenLocation = 0
          }else if(this.dataObj.machineRoomForm.slideDirection === 0){
            this.pingyimenLocation = 1
          }
          var bhtongdao = this.makemen({
            name: 'jifangmen',
            type: this.dataObj.machineRoomForm.patientType,
            chaoxiang: this.dataObj.machineRoomForm.patientDirection,
            // huadong: this.dataObj.machineRoomForm.slideDirection,
            huadong: this.pingyimenLocation,
            weizhi: this.dataObj.machineRoomForm.patientPosition,
            width: Number(this.dataObj.machineRoomForm.patientWidth),
            x:
              Number(this.dataObj.machineRoomForm.patientLocation) +
              this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            y: 0
          })
          this.bhtongdao = bhtongdao
        }
      }
      //  var pingyimenLocation = null
      //  var allDataParams = JSON.parse(sessionStorage.getItem('homeData'))
      //  if(allDataParams !== null && allDataParams.machineRoomForm !== null){
      //   if(allDataParams.machineRoomForm.slideDirection === '上' || allDataParams.machineRoomForm.slideDirection === '右'){
      //     this.pingyimenLocation = 1
      //   } else if(allDataParams.machineRoomForm.slideDirection === '下' || allDataParams.machineRoomForm.slideDirection === '左'){
      //     this.pingyimenLocation = 0
      //   }
      //  }
      
          console.log(this.dataObj.machineRoomForm, 'this.dataObj.machineRoomForm-----------------')
          if(this.dataObj.machineRoomForm.slideDirection === 1){
            this.pingyimenLocation = 0
          }else if(this.dataObj.machineRoomForm.slideDirection === 0){
            this.pingyimenLocation = 1
          }
      if (this.dataObj.machineRoomForm.patientPosition == 1) {
        if (
          this.dataObj.machineRoomForm.patientLocation != '' ||
          Number(this.dataObj.machineRoomForm.patientWidth) != 0
        ) {
         
          var bhtongdao = this.makemen({
            name: 'jifangmen',
            type: this.dataObj.machineRoomForm.patientType,
            chaoxiang: this.dataObj.machineRoomForm.patientDirection,
            huadong: this.pingyimenLocation,
            // huadong: this.dataObj.machineRoomForm.slideDirection,
            weizhi: this.dataObj.machineRoomForm.patientPosition,
            width: Number(this.dataObj.machineRoomForm.patientWidth),
            x:
              Number(this.dataObj.machineRoomForm.patientLocation) +
              this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            y:
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              this.dataObj.machineRoomForm.wallThickness +
              this.dataObj.machineRoomForm.width
          })
          this.bhtongdao = bhtongdao
        }
        // this.domJson['机房'].position.x = this.domJson['机房'].position.x + 30
      }
      if (this.dataObj.machineRoomForm.patientPosition == 2) {
        if (Number(this.dataObj.machineRoomForm.corridorWidth)) {
          var zoudao = [
            {
              style: 0,
              line: {
                x: -Number(this.dataObj.machineRoomForm.corridorWidth),
                y: 0,
                x2: -Number(this.dataObj.machineRoomForm.corridorWidth),
                y2:
                  this.dataObj.machineRoomForm.width +
                  this.dataObj.machineRoomForm.wallThickness * 2 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 2
              }
            },
            {
              style: 0,
              line: {
                x: -(
                  Number(this.dataObj.machineRoomForm.corridorWidth) +
                  this.dataObj.machineRoomForm.wallThickness +
                  Number(this.dataObj.machineRoomForm.decorateThickness)
                ),
                y: 0,
                x2: -(
                  Number(this.dataObj.machineRoomForm.corridorWidth) +
                  this.dataObj.machineRoomForm.wallThickness +
                  Number(this.dataObj.machineRoomForm.decorateThickness)
                ),
                y2:
                  this.dataObj.machineRoomForm.width +
                  this.dataObj.machineRoomForm.wallThickness * 2 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 2
              }
            }
          ]

          // this.domJson['机房'].position.x = this.domJson['机房'].position.x + 60
        }
        if (
          this.dataObj.machineRoomForm.patientLocation != '' ||
          Number(this.dataObj.machineRoomForm.patientWidth) != 0
        ) {
          var bhtongdao = this.makemen({
            name: 'jifangmen',
            type: this.dataObj.machineRoomForm.patientType,
            chaoxiang: this.dataObj.machineRoomForm.patientDirection,
            huadong: this.pingyimenLocation,
            weizhi: this.dataObj.machineRoomForm.patientPosition,
            width: Number(this.dataObj.machineRoomForm.patientWidth),
            x: 0,
            y:
              Number(this.dataObj.machineRoomForm.patientLocation) +
              this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness)
          })
          this.bhtongdao = bhtongdao
        }
      }
      if (this.dataObj.machineRoomForm.patientPosition == 3) {
        if (Number(this.dataObj.machineRoomForm.corridorWidth)) {
          var zoudao = [
            {
              style: 0,
              line: {
                x:
                  this.dataObj.machineRoomForm.length +
                  this.dataObj.machineRoomForm.wallThickness * 2 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 2 +
                  Number(this.dataObj.machineRoomForm.corridorWidth),
                y: 0,
                x2:
                  this.dataObj.machineRoomForm.length +
                  this.dataObj.machineRoomForm.wallThickness * 2 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 2 +
                  Number(this.dataObj.machineRoomForm.corridorWidth),
                y2:
                  this.dataObj.machineRoomForm.width +
                  this.dataObj.machineRoomForm.wallThickness * 2 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 2
              }
            },
            {
              style: 0,
              line: {
                x:
                  this.dataObj.machineRoomForm.length +
                  this.dataObj.machineRoomForm.wallThickness * 3 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 3 +
                  Number(this.dataObj.machineRoomForm.corridorWidth),
                y: 0,
                x2:
                  this.dataObj.machineRoomForm.length +
                  this.dataObj.machineRoomForm.wallThickness * 3 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 3 +
                  Number(this.dataObj.machineRoomForm.corridorWidth),
                y2:
                  this.dataObj.machineRoomForm.width +
                  this.dataObj.machineRoomForm.wallThickness * 2 +
                  Number(this.dataObj.machineRoomForm.decorateThickness) * 2
              }
            }
          ]
        }
        if (
          this.dataObj.machineRoomForm.patientLocation != '' ||
          Number(this.dataObj.machineRoomForm.patientWidth) != 0
        ) {
          var bhtongdao = this.makemen({
            name: 'jifangmen',
            type: this.dataObj.machineRoomForm.patientType,
            chaoxiang: this.dataObj.machineRoomForm.patientDirection,
            huadong: this.pingyimenLocation,
            // huadong: this.dataObj.machineRoomForm.slideDirection,
            weizhi: this.dataObj.machineRoomForm.patientPosition,
            width: Number(this.dataObj.machineRoomForm.patientWidth),
            x:
              this.dataObj.machineRoomForm.length +
              this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            y:
              Number(this.dataObj.machineRoomForm.patientLocation) +
              this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness)
          })
          this.bhtongdao = bhtongdao
        }
      }
      //边界设置
      console.log(this.domJson['机房'], 'this.domJson[机房]')
      this.bianjie = [
        this.domJson['机房'].position.x + qianghou * this.beishu,
        this.domJson['机房'].position.y + qianghou * this.beishu,
        this.domJson['机房'].position.x +
          (this.dataObj.machineRoomForm.length + qianghou) * this.beishu,
        this.domJson['机房'].position.y +
          (this.dataObj.machineRoomForm.width + qianghou) * this.beishu,
        this.domJson['机房'].position.x +
          (this.dataObj.controlRoomForm.location +
            this.dataObj.machineRoomForm.wallThickness) *
            this.beishu,
        this.domJson['机房'].position.y +
          (this.dataObj.machineRoomForm.width + qianghou * 2) * this.beishu,
        this.domJson['机房'].position.x +
          (this.dataObj.controlRoomForm.location +
            this.dataObj.controlRoomForm.length +
            this.dataObj.machineRoomForm.wallThickness) *
            this.beishu,
        this.domJson['机房'].position.y +
          (this.dataObj.controlRoomForm.width +
            this.dataObj.machineRoomForm.width +
            qianghou * 2) *
            this.beishu
      ]

      //机房墙
      if (
        this.dataObj.machineRoomForm.length &&
        this.dataObj.machineRoomForm.width &&
        this.dataObj.machineRoomForm.wallThickness
      ) {
        var wall = [
          {
            style: 0,
            line: {
              x: 0,
              y: 0,
              x2:
                this.dataObj.machineRoomForm.length +
                this.dataObj.machineRoomForm.wallThickness * 2 +
                Number(this.dataObj.machineRoomForm.decorateThickness) * 2,
              y2: 0
            }
          },
          {
            style: 0,
            line: {
              x:
                this.dataObj.machineRoomForm.length +
                this.dataObj.machineRoomForm.wallThickness * 2 +
                Number(this.dataObj.machineRoomForm.decorateThickness) * 2,
              y: 0,
              x2:
                this.dataObj.machineRoomForm.length +
                this.dataObj.machineRoomForm.wallThickness * 2 +
                Number(this.dataObj.machineRoomForm.decorateThickness) * 2,
              y2:
                this.dataObj.machineRoomForm.width +
                this.dataObj.machineRoomForm.wallThickness * 2 +
                Number(this.dataObj.machineRoomForm.decorateThickness) * 2
            }
          },
          {
            style: 0,
            line: {
              x:
                this.dataObj.machineRoomForm.length +
                this.dataObj.machineRoomForm.wallThickness * 2 +
                Number(this.dataObj.machineRoomForm.decorateThickness) * 2,
              y:
                this.dataObj.machineRoomForm.width +
                this.dataObj.machineRoomForm.wallThickness * 2 +
                Number(this.dataObj.machineRoomForm.decorateThickness) * 2,
              x2: 0,
              y2:
                this.dataObj.machineRoomForm.width +
                this.dataObj.machineRoomForm.wallThickness * 2 +
                Number(this.dataObj.machineRoomForm.decorateThickness) * 2
            }
          },
          {
            style: 0,
            line: {
              x: 0,
              y:
                this.dataObj.machineRoomForm.width +
                this.dataObj.machineRoomForm.wallThickness * 2 +
                Number(this.dataObj.machineRoomForm.decorateThickness) * 2,
              x2: 0,
              y2: 0
            }
          },
          {
            style: 0,
            line: {
              x:
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y:
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              x2:
                this.dataObj.machineRoomForm.length +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y2:
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness)
            }
          },
          {
            style: 0,
            line: {
              x:
                this.dataObj.machineRoomForm.length +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y:
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              x2:
                this.dataObj.machineRoomForm.length +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y2:
                this.dataObj.machineRoomForm.width +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness)
            }
          },
          {
            style: 0,
            line: {
              x:
                this.dataObj.machineRoomForm.length +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y:
                this.dataObj.machineRoomForm.width +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              x2:
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y2:
                this.dataObj.machineRoomForm.width +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness)
            }
          },
          {
            style: 0,
            line: {
              x:
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y:
                this.dataObj.machineRoomForm.width +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              x2:
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y2:
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness)
            }
          }
        ]
      }
      //控制室
      if (this.dataObj.controlRoomForm.location != undefined) {
        if (sessionStorage.getItem('isHaveKong') == 'true') {
          var kongzhishi = [
            {
              //左竖1
              style: 0,
              line: {
                x: this.dataObj.controlRoomForm.location,
                y: this.dataObj.machineRoomForm.width + qianghou * 2,
                x2: this.dataObj.controlRoomForm.location,
                y2:
                  this.dataObj.machineRoomForm.width +
                  this.dataObj.machineRoomForm.wallThickness +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width
              }
            },
            {
              //左竖2
              style: 0,
              line: {
                x:
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y: this.dataObj.machineRoomForm.width + qianghou * 2,
                x2:
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y2:
                  this.dataObj.machineRoomForm.width +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width
              }
            },
            {
              //右竖2
              style: 0,
              line: {
                x:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y: this.dataObj.machineRoomForm.width + qianghou * 2,
                x2:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y2:
                  this.dataObj.machineRoomForm.width +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width
              }
            },
            {
              //右竖1
              style: 0,
              line: {
                x:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness * 2,
                y: this.dataObj.machineRoomForm.width + qianghou * 2,
                x2:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness * 2,
                y2:
                  this.dataObj.machineRoomForm.width +
                  this.dataObj.machineRoomForm.wallThickness +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width
              }
            },
            {
              //下横2
              style: 0,
              line: {
                x:
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y:
                  this.dataObj.machineRoomForm.width +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width,
                x2:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y2:
                  this.dataObj.machineRoomForm.width +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width
              }
            },
            {
              //下横1
              style: 0,
              line: {
                x: this.dataObj.controlRoomForm.location,
                y:
                  this.dataObj.machineRoomForm.width +
                  this.dataObj.machineRoomForm.wallThickness +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width,
                x2:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness * 2,
                y2:
                  this.dataObj.machineRoomForm.width +
                  this.dataObj.machineRoomForm.wallThickness +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width
              }
            }
          ]
          //范围内补白线左
          if (
            this.dataObj.controlRoomForm.location >= 0 &&
            this.dataObj.controlRoomForm.location <=
              this.dataObj.machineRoomForm.length + qianghou
          ) {
            kongzhishi.push({
              style: 2,
              line: {
                x: this.dataObj.controlRoomForm.location,
                y: this.dataObj.machineRoomForm.width + qianghou * 2,
                x2:
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y2: this.dataObj.machineRoomForm.width + qianghou * 2
              }
            })
          }
          //范围内补白线右
          if (
            this.dataObj.controlRoomForm.length +
              this.dataObj.controlRoomForm.location +
              this.dataObj.machineRoomForm.wallThickness >=
              0 &&
            this.dataObj.controlRoomForm.length +
              this.dataObj.controlRoomForm.location +
              this.dataObj.machineRoomForm.wallThickness * 2 <=
              this.dataObj.machineRoomForm.length + qianghou * 2
          ) {
            kongzhishi.push({
              style: 2,
              line: {
                x:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y: this.dataObj.machineRoomForm.width + qianghou * 2,
                x2:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness * 2,
                y2: this.dataObj.machineRoomForm.width + qianghou * 2
              }
            })
          }
          //范围外补线左
          if (this.dataObj.controlRoomForm.location < 0) {
            kongzhishi.push({
              style: 0,
              line: {
                x: this.dataObj.controlRoomForm.location,
                y: this.dataObj.machineRoomForm.width + qianghou,
                x2: 0,
                y2: this.dataObj.machineRoomForm.width + qianghou
              }
            })
            kongzhishi.push({
              style: 0,
              line: {
                x:
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness,
                y: this.dataObj.machineRoomForm.width + qianghou * 2,
                x2: 0,
                y2: this.dataObj.machineRoomForm.width + qianghou * 2
              }
            })
            kongzhishi.push({
              style: 0,
              line: {
                x: this.dataObj.controlRoomForm.location,
                y: this.dataObj.machineRoomForm.width + qianghou,
                x2: this.dataObj.controlRoomForm.location,
                y2: this.dataObj.machineRoomForm.width + qianghou * 2
              }
            })
            kongzhishi.push({
              style: 2,
              line: {
                x: 0,
                y: this.dataObj.machineRoomForm.width + qianghou,
                x2: 0,
                y2: this.dataObj.machineRoomForm.width + qianghou * 2 - 0.1
              }
            })
          }
          //范围外补线右
          if (
            this.dataObj.controlRoomForm.length +
              this.dataObj.controlRoomForm.location +
              this.dataObj.machineRoomForm.wallThickness * 2 >
            this.dataObj.machineRoomForm.length + qianghou * 2
          ) {
            kongzhishi.push({
              style: 0,
              line: {
                x: this.dataObj.machineRoomForm.length + qianghou * 2,
                y: this.dataObj.machineRoomForm.width + qianghou,
                x2:
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.machineRoomForm.wallThickness * 2,
                y2: this.dataObj.machineRoomForm.width + qianghou
              }
            })
            kongzhishi.push({
              style: 0,
              line: {
                x: this.dataObj.machineRoomForm.length + qianghou * 2,
                y: this.dataObj.machineRoomForm.width + qianghou * 2,
                x2:
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.machineRoomForm.wallThickness,
                y2: this.dataObj.machineRoomForm.width + qianghou * 2
              }
            })
            kongzhishi.push({
              style: 0,
              line: {
                x:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness * 2,
                y: this.dataObj.machineRoomForm.width + qianghou,
                x2:
                  this.dataObj.controlRoomForm.length +
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness * 2,
                y2: this.dataObj.machineRoomForm.width + qianghou * 2
              }
            })
            kongzhishi.push({
              style: 2,
              line: {
                x: this.dataObj.machineRoomForm.length + qianghou * 2,
                y: this.dataObj.machineRoomForm.width + qianghou,
                x2: this.dataObj.machineRoomForm.length + qianghou * 2,
                y2: this.dataObj.machineRoomForm.width + qianghou * 2 - 0.1
              }
            })
          }
        }

        //技师通道
        if (Number(this.dataObj.machineRoomForm.technicianWidth)) {
          var jstongdao = this.makemen({
            name: 'jishimen',
            type: this.dataObj.machineRoomForm.technicianType,
            chaoxiang: this.dataObj.machineRoomForm.technicianDirection,
            huadong: this.dataObj.machineRoomForm.slideDirection,
            weizhi: 1,
            width: Number(this.dataObj.machineRoomForm.technicianWidth),
            x:
              Number(this.dataObj.machineRoomForm.technicianLocation) +
              qianghou,
            y: this.dataObj.machineRoomForm.width + qianghou
          })
        }

        var guanchachuang = [
          {
            style: 3,
            rect: {
              x: this.dataObj.controlRoomForm.windowLocation + qianghou,
              y: this.dataObj.machineRoomForm.width + qianghou,
              w: this.dataObj.controlRoomForm.windowWidth,
              h: qianghou
            }
          },
          {
            style: 3,
            line: {
              x: this.dataObj.controlRoomForm.windowLocation + qianghou,
              y: this.dataObj.machineRoomForm.width + (qianghou * 3) / 2,
              x2:
                this.dataObj.controlRoomForm.windowLocation +
                qianghou +
                this.dataObj.controlRoomForm.windowWidth,
              y2: this.dataObj.machineRoomForm.width + (qianghou * 3) / 2
            }
          }
        ]
        if (sessionStorage.getItem('isHaveKong') == 'true') {
          if (this.dataObj.controlRoomForm.doorPosition == 1) {
            if (
              this.dataObj.controlRoomForm.location != '' ||
              Number(this.dataObj.controlRoomForm.doorWidth) != 0
            ) {
              var kongzhimenLocation = JSON.parse(sessionStorage.getItem('homeData'))
              var kongzhishiDoorLocation = null
              if(kongzhimenLocation.point !== null){
                if(kongzhimenLocation.point == "右上角"){
                  this.kongzhishiDoorLocation = kongzhimenLocation.machineRoomForm.length - kongzhimenLocation.controlRoomForm.doorLocation - kongzhimenLocation.controlRoomForm.doorWidth + kongzhimenLocation.machineRoomForm.wallThickness  - kongzhimenLocation.controlRoomForm.location
                } else if(kongzhimenLocation.point == "左上角"){
                  this.kongzhishiDoorLocation = kongzhimenLocation.controlRoomForm.doorLocation + kongzhimenLocation.machineRoomForm.wallThickness + kongzhimenLocation.controlRoomForm.location
                }
              }
              var kongzhimen = this.makemen({
                name: 'kongzhimen',
                type: this.dataObj.controlRoomForm.doorType,
                chaoxiang: this.dataObj.controlRoomForm.doorDirection,
                huadong: this.dataObj.machineRoomForm.slideDirection,
                weizhi: this.dataObj.controlRoomForm.doorPosition,
                width: Number(this.dataObj.controlRoomForm.doorWidth),
                x:this.kongzhishiDoorLocation,
                // x:kongzhimenLocation.machineRoomForm.length - kongzhimenLocation.controlRoomForm.doorLocation - kongzhimenLocation.controlRoomForm.doorWidth + kongzhimenLocation.machineRoomForm.wallThickness,
                // x:
                //   this.dataObj.controlRoomForm.location +
                //   this.dataObj.machineRoomForm.wallThickness +
                //   Number(this.dataObj.controlRoomForm.doorLocation),
                y:
                  this.dataObj.machineRoomForm.width +
                  qianghou * 2 +
                  this.dataObj.controlRoomForm.width
              })
            }
          }
          if (this.dataObj.controlRoomForm.doorPosition == 2) {
            if (
              this.dataObj.controlRoomForm.location != '' ||
              Number(this.dataObj.controlRoomForm.doorWidth) != 0
            ) {
              var kongzhimen = this.makemen({
                name: 'kongzhimen',
                type: this.dataObj.controlRoomForm.doorType,
                chaoxiang: this.dataObj.controlRoomForm.doorDirection,
                huadong: this.dataObj.machineRoomForm.slideDirection,
                weizhi: this.dataObj.controlRoomForm.doorPosition,
                width: Number(this.dataObj.controlRoomForm.doorWidth),
                x: this.dataObj.controlRoomForm.location,
                y: qianghou + Number(this.dataObj.controlRoomForm.doorLocation)
              })
            }
          }
          if (this.dataObj.controlRoomForm.doorPosition == 3) {
            if (
              this.dataObj.controlRoomForm.location != '' ||
              Number(this.dataObj.controlRoomForm.doorWidth) != 0
            ) {
              var kongzhimen = this.makemen({
                name: 'kongzhimen',
                type: this.dataObj.controlRoomForm.doorType,
                chaoxiang: this.dataObj.controlRoomForm.doorDirection,
                huadong: this.dataObj.machineRoomForm.slideDirection,
                weizhi: this.dataObj.controlRoomForm.doorPosition,
                width: Number(this.dataObj.controlRoomForm.doorWidth),
                x:
                  this.dataObj.controlRoomForm.location +
                  this.dataObj.machineRoomForm.wallThickness +
                  this.dataObj.controlRoomForm.length,
                y: qianghou + Number(this.dataObj.controlRoomForm.doorLocation)
              })
            }
          }
        }
      }

      //柱子
      if (this.dataObj.zhuForm.length > 0) {
        var zhuzi = []
        this.dataObj.zhuForm.forEach((v, i) => {
          // var zhuziParams = null
          // if(this.dataObj.point === 0){
          //   this.zhuziParams = v.x + qianghou
          // }else if(this.dataObj.point === 1){
          //   this.zhuziParams = v.x + qianghou - v.height
          // }
          zhuzi.push({
            style: 0,
            rect: {
              x: v.x + qianghou,
              y: v.y + qianghou,
              w: v.height,
              h: v.width
            }
          })
          // if(this.dataObj.point === 1 ){
          if(this.dataObj.point === 1 && this.zhuformState === 0){
            // zhuzi[i].rect.x = (this.dataObj.machineRoomForm.length / 2 < v.x) ? (v.x + qianghou - v.height) : (v.x + qianghou)
            zhuzi[i].rect.x = v.x + qianghou - v.height
            setTimeout(() => {
              this.zhuformState++
            }, 1000)
          }
        })
      }

      //生成标注json
      if (this.dataObj.machineRoomForm.patientPosition == 3) {
        this.makeBiaozhuJson(
          'jichangLeft',
          qianghou,
          qianghou,
          qianghou,
          qianghou + this.dataObj.machineRoomForm.width,
          -1,
          1
        )
      } else {
        this.makeBiaozhuJson(
          'jichangLeft',
          qianghou * 2 + this.dataObj.machineRoomForm.length,
          qianghou,
          qianghou * 2 + this.dataObj.machineRoomForm.length,
          qianghou + this.dataObj.machineRoomForm.width,
          1,
          1
        )
      }

      this.makeBiaozhuJson(
        'jichangTop',
        qianghou,
        qianghou - 30,
        qianghou + this.dataObj.machineRoomForm.length,
        qianghou - 30,
        -1,
        1
      )

      if (sessionStorage.getItem('isHaveKong') == 'true') {
        if (this.dataObj.controlRoomForm.doorPosition == 3) {
          this.makeBiaozhuJson(
            'kongzhiLeft',
            this.dataObj.machineRoomForm.wallThickness +
              this.dataObj.controlRoomForm.location,
            qianghou * 2 + this.dataObj.machineRoomForm.width,
            this.dataObj.machineRoomForm.wallThickness +
              this.dataObj.controlRoomForm.location,
            qianghou * 2 +
              this.dataObj.machineRoomForm.width +
              this.dataObj.controlRoomForm.width,
            -1,
            1
          )
        } else {
          this.makeBiaozhuJson(
            'kongzhiLeft',
            qianghou * 2 + this.dataObj.machineRoomForm.length,
            qianghou * 2 + this.dataObj.machineRoomForm.width,
            qianghou * 2 + this.dataObj.machineRoomForm.length,
            qianghou * 2 +
              this.dataObj.machineRoomForm.width +
              this.dataObj.controlRoomForm.width,
            1,
            1
          )
        }

        this.makeBiaozhuJson(
          'kongzhiBottom',
          this.dataObj.machineRoomForm.wallThickness +
            this.dataObj.controlRoomForm.location,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width,
          this.dataObj.machineRoomForm.wallThickness +
            this.dataObj.controlRoomForm.location +
            this.dataObj.controlRoomForm.length,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width,
          1,
          1
        )
      }
      if(this.dataObj.point === 0){
         this.makeBiaozhuJson(
          'guanchachuangLeft',
          qianghou,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width -
            100,
          this.dataObj.controlRoomForm.windowLocation +
            qianghou,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width -
            100,
          1
        )
      }
     
      this.makeBiaozhuJson(
        'guanchachuang',
        this.dataObj.controlRoomForm.windowLocation + qianghou,
        qianghou * 2 +
          this.dataObj.machineRoomForm.width +
          this.dataObj.controlRoomForm.width -
          100,
        this.dataObj.controlRoomForm.windowLocation +
          qianghou +
          this.dataObj.controlRoomForm.windowWidth,
        qianghou * 2 +
          this.dataObj.machineRoomForm.width +
          this.dataObj.controlRoomForm.width -
          100,
        1
      )

      if (
        Number(this.dataObj.machineRoomForm.technicianLocation) >
        this.dataObj.controlRoomForm.windowLocation +
          this.dataObj.controlRoomForm.location
      ) {
        this.makeBiaozhuJson(
          'guanchachuangleft',
          this.dataObj.machineRoomForm.wallThickness +
            this.dataObj.controlRoomForm.location,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width -
            100,
          this.dataObj.controlRoomForm.windowLocation + qianghou,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width -
            100,
          1
        )
        this.makeBiaozhuJson(
          'guanchachuangright',
          this.dataObj.controlRoomForm.windowLocation +
            qianghou +
            this.dataObj.controlRoomForm.windowWidth,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width -
            100,
          Number(this.dataObj.machineRoomForm.technicianLocation) + qianghou,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width -
            100,
          1
        )
      } else {
        this.makeBiaozhuJson(
          'guanchachuangright',
          this.dataObj.controlRoomForm.windowLocation +
            qianghou +
            this.dataObj.controlRoomForm.windowWidth,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width -
            100,
          this.dataObj.machineRoomForm.wallThickness +
            this.dataObj.controlRoomForm.location +
            this.dataObj.controlRoomForm.length,
          qianghou * 2 +
            this.dataObj.machineRoomForm.width +
            this.dataObj.controlRoomForm.width -
            100,
          1
        )
        // this.makeBiaozhuJson(
        //   'guanchachuangright',
        //   this.dataObj.controlRoomForm.windowLocation +
        //     qianghou +
        //     this.dataObj.controlRoomForm.windowWidth,
        //   qianghou * 2 +
        //     this.dataObj.machineRoomForm.width +
        //     this.dataObj.controlRoomForm.width -
        //     100,
        //     this.dataObj.controlRoomForm.location +
        //     this.dataObj.controlRoomForm.length,
        //   qianghou * 2 +
        //     this.dataObj.machineRoomForm.width +
        //     this.dataObj.controlRoomForm.width -
        //     100,
        //   1
        // )
        if(this.dataObj.point === 0){
          // this.makeBiaozhuJson(
          //   'jishimenright',
          //   Number(this.dataObj.machineRoomForm.technicianLocation) +
          //     qianghou +
          //     Number(this.dataObj.machineRoomForm.technicianWidth),
          //   qianghou * 2 +
          //     this.dataObj.machineRoomForm.width +
          //     this.dataObj.controlRoomForm.width -
          //     100,
          //   qianghou +
          //     this.dataObj.controlRoomForm.windowLocation +
          //     this.dataObj.controlRoomForm.location,
          //   qianghou * 2 +
          //     this.dataObj.machineRoomForm.width +
          //     this.dataObj.controlRoomForm.width -
          //     100,
          //   1
          // )
        }else{
          if(this.dataObj.machineRoomForm.patientLocation < this.dataObj.controlRoomForm){
            this.makeBiaozhuJson(
              'jishimenright',
              Number(this.dataObj.machineRoomForm.technicianLocation) +
                qianghou +
                Number(this.dataObj.machineRoomForm.technicianWidth),
              qianghou * 2 +
                this.dataObj.machineRoomForm.width +
                this.dataObj.controlRoomForm.width -
                100,
              qianghou*2 +
                this.dataObj.machineRoomForm.length -
                this.dataObj.controlRoomForm.windowLocation - this.dataObj.controlRoomForm.windowWidth,
              qianghou * 2 +
                this.dataObj.machineRoomForm.width +
                this.dataObj.controlRoomForm.width -
                100,
              1
            )
          }else{
            this.makeBiaozhuJson(
              'jishimenright',
              Number(this.dataObj.machineRoomForm.technicianLocation) +
                qianghou +
                Number(this.dataObj.machineRoomForm.technicianWidth),
              qianghou * 2 +
                this.dataObj.machineRoomForm.width +
                this.dataObj.controlRoomForm.width -
                100,
              qianghou*2 +
                this.dataObj.machineRoomForm.length -
                this.dataObj.controlRoomForm.windowLocation - this.dataObj.controlRoomForm.windowWidth,
              qianghou * 2 +
                this.dataObj.machineRoomForm.width +
                this.dataObj.controlRoomForm.width -
                100,
              1
            )
          }
          
        }  
        console.log(this.makeBiaozhuJson, 'this.makeBiaozhuJson')
      }

      wall = wall.concat(zoudao)
      wall = wall.concat(bhtongdao)
      wall = wall.concat(kongzhishi)
      wall = wall.concat(jstongdao)
      wall = wall.concat(guanchachuang)
      wall = wall.concat(kongzhimen)
      if (zhuzi != undefined) wall = wall.concat(zhuzi)
      this.domJson['机房'].child = wall
      this.makeObj(this.domJson['机房'])

      this.initbiaozhu()
      //this.makeObj(this.domJson['机房']);
      // this.dataObj.machineRoomForm
      // this.json3.forEach(v => {
      // 	if(v.name==name){
      // 		let tmpaddjson=JSON.parse(JSON.stringify(v));
      // 		tmpaddjson.name=tmpaddjson.name+"_"+new Date().getTime();
      // 		this.json2.push(tmpaddjson)
      // 		this.makeObj(tmpaddjson);
      // 	}
      // })
    },
    makeObj(data) {
      console.log(data, '接收到的参数---------->')
      
      this.domGrap[data.name] = new PIXI.Container()
      let sundom = new PIXI.Graphics()
      var graphics = new PIXI.Graphics(); 
        data.child.forEach((v) => {
          if (v) {
            this.makeGraph(data.styles[v.style], v, sundom)
          }
          
          if(data.code !== undefined && data.code === 20){
            graphics.lineStyle(4, 0xFFFFFF, 1); 
            graphics.moveTo(data.child[data.child.length-1].line.y, data.child[data.child.length-1].line.x); 
            graphics.lineTo(data.child[data.child.length-1].line.y2,data.child[data.child.length-1].line.x2); 
          }
        })
      

      this.domGrap[data.name].addChild(sundom)
      this.domGrap[data.name].addChild(graphics)
      if (data.position.x == 0 && data.position.y == 0) {
        console.log(this.bianjie)
        this.domGrap[data.name].x =
          this.bianjie[0] + (data.width / 2) * this.beishu
        this.domGrap[data.name].y =
          this.bianjie[1] + (data.length / 2) * this.beishu
      } else {
        this.domGrap[data.name].x = data.position.x
        this.domGrap[data.name].y = data.position.y
      }

      this.domGrap[data.name].scale.x = this.beishu
      this.domGrap[data.name].scale.y = this.beishu

      if (data.rotation != undefined)
        this.domGrap[data.name].rotation = (data.rotation / 180) * Math.PI
      else this.domGrap[data.name].rotation = Math.PI / 2

      if (data.move == true) {
        this.domGrap[data.name].interactive = true //响应交互
        this.domGrap[data.name].buttonMode = true //鼠标变手型
        this.domGrap[data.name].on('pointerdown', (e) => {
          if (
            this.delDom == data.name &&
            this.dbclicktime > new Date().getTime() - 300
          ) {
            if (confirm('是否删除？')) {
              this.app.stage.removeChild(this.domGrap[data.name])
              this.domGrap[data.name].destroy({
                children: true
              })
              delete this.domGrap[data.name]

              delete this.domJson[data.name]
              if (jsonname == 'aircondition') {
                delete this.dataObj['aircondition'][data.name]
              } else {
                this.dataObj[data.name] = { x: 0, y: 0, r: 0 }
              }
            }
          } else {
            this.dbclicktime = new Date().getTime()
            this.moveDom = data.name
            this.delDom = data.name
          }
        })
        var jsonname = data.name.split('_')[0]
        if (jsonname == 'aircondition') {
          this.dataObj[jsonname][data.name] = {
            x:
              Math.round(
                ((this.domGrap[data.name].x - this.bianjie[0]) / this.beishu) *
                  10
              ) / 10,
            y:
              Math.round(
                ((this.domGrap[data.name].y - this.bianjie[1]) / this.beishu) *
                  10
              ) / 10,
            r: (this.domGrap[data.name].rotation * 180) / Math.PI
          }
        } else {
          this.dataObj[data.name] = {
            x:
              Math.round(
                ((this.domGrap[data.name].x - this.bianjie[0]) / this.beishu) *
                  10
              ) / 10,
            y:
              Math.round(
                ((this.domGrap[data.name].y - this.bianjie[1]) / this.beishu) *
                  10
              ) / 10,
            r: (this.domGrap[data.name].rotation * 180) / Math.PI
          }
        }
        if (data.name2 == '空调' || data.name2 == '现场配电箱') {
          this.domGrap[data.name].zIndex = -1
        }
      }

      this.app.stage.addChild(this.domGrap[data.name])
    },
    makeNum(num, type = 0, x = 0, y = 0) {
      this.domTxtGrap[num] = new PIXI.Text('#' + num, {
        fontFamily: 'Arial',
        fontSize: this.zihao + 2,
        fill: '0xff0000',
        align: 'center'
      })
      // this.domTxtGrap[num].beginFill(0xffffff);
      if (x != 0 || y != 0) {
        this.domTxtGrap[num].x = x
        this.domTxtGrap[num].y = y
      } else {
        if (type == 1) this.domTxtGrap[num].x = num * 30 * type
        else this.domTxtGrap[num].x = 160
        this.domTxtGrap[num].y = 360
      }

      this.domTxtGrap[num].interactive = true //响应交互
      this.domTxtGrap[num].buttonMode = true //鼠标变手型
      this.domTxtGrap[num].on('pointerdown', (e) => {
        this.moveDom = e.target._text.replace('#', '')
      })
      this.app.stage.addChild(this.domTxtGrap[num])
    },
    makeGraph(style, data, tempGrap) {
      // let tempGrap=this.domGrap[name]

      //轮廓
      tempGrap.lineStyle(style.weight, style.color, style.alpha)
      //填充
      if (data.fill != undefined) tempGrap.beginFill(data.fill)
      //矩形
      if (data.rect != undefined)
        tempGrap.drawRect(data.rect.x, data.rect.y, data.rect.w, data.rect.h)
      //圆形
      if (data.circle != undefined)
        tempGrap.drawCircle(data.circle.x, data.circle.y, data.circle.radius)
      //椭圆
      if (data.ellipse != undefined)
        tempGrap.drawEllipse(
          data.ellipse.x,
          data.ellipse.y,
          data.ellipse.w,
          data.ellipse.h
        )
      //圆角矩形
      if (data.roundedRect != undefined)
        tempGrap.drawRoundedRect(
          data.roundedRect.x,
          data.roundedRect.y,
          data.roundedRect.w,
          data.roundedRect.h,
          data.roundedRect.radius
        )
      //线段
      if (data.line != undefined) {
        tempGrap.moveTo(data.line.x, data.line.y)
        tempGrap.lineTo(data.line.x2, data.line.y2)
      }
      //虚线
      if (data.dash != undefined) {
        this.drawDash(
          tempGrap,
          data.dash.x,
          data.dash.y,
          data.dash.x2,
          data.dash.y2
        )
      }
      //多边形
      if (data.polygon != undefined) tempGrap.drawPolygon(data.polygon)
      //弧形
      if (data.arc != undefined)
        tempGrap.arc(
          data.arc.x,
          data.arc.y,
          data.arc.radius,
          data.arc.startAngle,
          data.arc.endAngle
        )

      //结束填充
      if (data.fill != undefined) tempGrap.endFill()
    },
    dragNextStep() {
      // console.log(this.dataObj, '888888888888')
      // if(this.stateCad === 0){
      // // if(this.status == 'plan'){
      //   var localData = JSON.parse(sessionStorage.getItem('homeData'))
      //   this.dataObj.controlRoomForm.doorLocation = this.dataObj.controlRoomForm.doorLocation + localData.controlRoomForm.location
      //   if(this.dataObj.zhuForm.length > 0){
      //     this.dataObj.zhuForm.forEach((val) => {
      //       if(this.dataObj.point === 1){
      //         val.x = val.x-val.height
      //       }
      //     })
      //   }
      //   this.stateCad++
      // }
     
      console.log('dragNextStep ---- plan')
      this.status = 'plan'
      this.$store.commit('changeStep', this.status)
      localStorage.setItem('currentStatus', this.status)
	  this.tmpDomJson={};
      for (let i in this.domGrap) {
        if (i != '机房') {
          this.app.stage.removeChild(this.domGrap[i])
          this.domGrap[i].destroy({
            children: true
          })
          delete this.domGrap[i]
		  if(this.domJson[i]!=undefined){
			  this.tmpDomJson[i]=JSON.parse(JSON.stringify(this.domJson[i]));
			  delete this.domJson[i]
		  }
        }
      }
      // axios.get("./response.json").then((response) => {
      // 	this.fanganjson = response.data.data;
      // });
      this.autoLayout()
    },
    choosePlan(index) {
      this.$set(
        this.dataObj,
        'extJson',
        this.fanganjson.layoutSchemeExports[index]
      )
      this.planIndex = index
      localStorage.setItem('planIndex', this.planIndex)
      for (let i in this.domGrap) {
        if (i != '机房') {
          this.app.stage.removeChild(this.domGrap[i])
          this.domGrap[i].destroy({
            children: true
          })
          delete this.domGrap[i]
          delete this.domJson[i]
        }
      }
      console.log(this.beishu, 'this.beishu****')
      console.log(this.bianjie, 'this.bianjie****')
      console.log(this.fanganjson.layoutSchemeExports[index].deviceExportData, 'layoutSchemeExports****')
      console.log(this.fanganjson.drawData, 'drawData****')
      this.dataObj.mainDevice.mainDeviceId = index
      this.fanganjson.layoutSchemeExports[index].deviceExportData.forEach(
        (v) => {
          this.fanganjson.drawData.forEach((k,index) => {
            // let tmpjsonKong = JSON.parse(JSON.stringify(k))
            // if(tmpjsonKong.code === 7 || tmpjsonKong.code === 15){
            //   this.makeObj(tmpjsonKong)
            // }
            if (k !== undefined && v.type == k.code) {
              let tmpjson = JSON.parse(JSON.stringify(k))
              console.log(tmpjson, '********************')
              tmpjson.name2 = tmpjson.name
              if(tmpjson.code !== 7 || tmpjson.code !== 15){
                tmpjson.position.x = v.point.x * this.beishu + this.bianjie[0]
                tmpjson.position.y = v.point.y * this.beishu + this.bianjie[1]
              }
              
              if(tmpjson.code === 7 || tmpjson.code === 15){
                if(tmpjson.position.y < 0){
                  tmpjson.position.y = -(tmpjson.position.y)
                }
                if(tmpjson.position.x < 0){
                  tmpjson.position.x = -(tmpjson.position.x)
                }
              }
              if(tmpjson.code === 20){
                tmpjson.position.x = tmpjson.position.x - 1
                tmpjson.position.y = tmpjson.position.y+1
              }
              
              if(tmpjson.code === 2){
                if(tmpjson.position.y < 0){
                  tmpjson.position.y = this.bianjie[5]
                }
              }
              
              if (v.angle != null)
                tmpjson.rotation =
                  v.angle.value > 180 ? v.angle.value - 360 : v.angle.value
              tmpjson.name = v.fileId
              this.makeObj(tmpjson)


               
              if (tmpjson.name2 == '检查床' ) {
                this.jijiax =
                  Math.round(
                    ((tmpjson.position.x - this.bianjie[0]) / this.beishu) * 10
                  ) / 10
                this.jijiay =
                  Math.round(
                    ((tmpjson.position.y - this.bianjie[1]) / this.beishu) * 10
                  ) / 10
                    if (this.dataObj.point == 1) {
                      this.jijiaxRight = Number((this.dataObj.machineRoomForm.length - this.jijiax).toString().match(/^\d+(?:\.\d{0,2})?/))
                      // this.jijiaxRight = (this.dataObj.machineRoomForm.length - this.jijiax).toFixed(1)
                    }else{
                  this.jijiaxRight =  this.jijiax 
                    }
                this.gantryAngle = tmpjson.rotation
                    console.log(this.gantryAngle, 'this.gantryAngle---------------')
                // console.log("tmpjson.rotation" ,this.fanganjson,v.type,v,index,this.fanganjson.layoutSchemeExports[index])
                this.jijialength = tmpjson.rotation.length
                this.jijiawidth = tmpjson.rotation.width
                this.jijiadom = tmpjson.name
                this.dataObj.mainDevice.point.x = Number(this.jijiax)
                this.dataObj.mainDevice.point.y = Number(this.jijiay)
                this.dataObj.mainDevice.angle = this.gantryAngle
                this.dataObj.extJson.deviceExportData.forEach((elem) => {
                  if (elem.fileId == v.fileId) {
                    elem.point.x = Number(this.jijiax)
                    elem.point.y = Number(this.jijiay)
                    elem.angle.value = this.gantryAngle
                  }
                })
                if (this.dataObj.point == 0) {
                  this.makeBiaozhuJson(
                    'jianchachuangtop',
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness) +
                      v.point.x,
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    -1
                  )
                  this.makeBiaozhuJson(
                    'jianchachuangleft',
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness) +
                      v.point.y,
                    -1
                  )
                } else {
                  this.makeBiaozhuJson(
                    'jianchachuangtop',
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness) +
                      v.point.x,
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness) +
                      this.dataObj.machineRoomForm.length,
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    -1
                  )
                  this.makeBiaozhuJson(
                    'jianchachuangleft',
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness) +
                      this.dataObj.machineRoomForm.length,
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness),
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness) +
                      this.dataObj.machineRoomForm.length,
                    this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness) +
                      v.point.y,
                    1
                  )
                }
                this.initbiaozhu()
              }
            }
          })
        }
      )
      this.fanganjson.layoutSchemeExports[index].deviceExportData.forEach(
        (item) => {
          if (this.dataObj.examinationCouchName == item.deviceName) {
              this.dataObj.mainDevice.point.x = Number(item.point.x)
              this.dataObj.mainDevice.point.y = Number(item.point.y)
          }
        }
      )
       console.log(this.fanganjson.drawData, '88888888888888888***************')
      console.log('extJson========>', this.dataObj.extJson)
    },
    unique(arr) {
      if (!Array.isArray(arr)) {
        console.log('type error!')
        return
    }
    let res = [],
        obj = {}
    for (let i = 0; i < arr.length; i++) {
        if (!obj[arr[i]]) {
            res.push(arr[i])
            obj[arr[i]] = 1
        } else {
            obj[arr[i]]++
        }
    }
    return res
    },
    // 自动布局
    autoLayout() {
      this.toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      let second = 120
      this.timer3 = setInterval(() => {
        second--
        if (second === 0) {
          clearInterval(this.timer3)
          this.toast.clear()
          Dialog.alert({
            message: '服务器超时,请重试!!!'
          }).then(() => {
            this.$bus.$emit('goBack')
          })
        }
      }, 1000)
      if (sessionStorage.getItem('isHaveKong') == 'false') {
        this.dataObj.controlRoomForm.location = 0
        this.dataObj.controlRoomForm.length = 0
        this.dataObj.controlRoomForm.width = 0
        this.dataObj.controlRoomForm.doorPosition = 0
        this.dataObj.controlRoomForm.doorType = 0
        this.dataObj.controlRoomForm.doorLocation = 0
        this.dataObj.controlRoomForm.doorWidth = 0
        this.dataObj.controlRoomForm.doorDirection = 0
      }
      this.dataObj.machineRoomForm.decorateThickness = Number(
        this.dataObj.machineRoomForm.decorateThickness
      )
      this.dataObj.machineRoomForm.patientLocation = Number(
        this.dataObj.machineRoomForm.patientLocation
      )
      this.dataObj.machineRoomForm.patientWidth = Number(
        this.dataObj.machineRoomForm.patientWidth
      )
      this.dataObj.machineRoomForm.corridorWidth = Number(
        this.dataObj.machineRoomForm.corridorWidth
      )
      this.dataObj.machineRoomForm.technicianLocation = Number(
        this.dataObj.machineRoomForm.technicianLocation
      )
      this.dataObj.machineRoomForm.technicianWidth = Number(
        this.dataObj.machineRoomForm.technicianWidth
      )
      this.dataObj.controlRoomForm.doorLocation = Number(
        this.dataObj.controlRoomForm.doorLocation
      )
      this.dataObj.controlRoomForm.doorWidth = Number(
        this.dataObj.controlRoomForm.doorWidth
      )
      console.log('自动布局参数', this.dataObj)
       this.demo(this.dataObj)
      AutoLayout(this.dataObj).then((res) => {
        console.log(res, '55555')
        console.log(res.data, '5555566')
        if (res.data.success) {
          console.log(res.data.data, 'res.data')
          clearInterval(this.timer3)
          this.handPositionJson = res.data.data
          res.data.data?.checkDimPoints.forEach((val) => {
            this.newField.push(val)
          })
          let processingData = res.data.data
          console.log(processingData, 'processingData9999999')
          if (this.dataObj.systemName.indexOf('Symbia') == 0) {
            if(processingData.mainDeviceDrawData['左手位'] !== undefined){
              processingData.drawData.push(processingData.mainDeviceDrawData['左手位'])
            }else{
              processingData.drawData.push(processingData.mainDeviceDrawData['诊断床，33°旋转带ICC和AQC'])
            }
            for (let i in processingData.mainDeviceDrawData) {
              processingData.drawData.push(processingData.mainDeviceDrawData[i])
            }
          } else {
            for (let i in processingData.mainDeviceDrawData) {
              processingData.drawData.push(processingData.mainDeviceDrawData[i])
            }
          }
          // 去重
          if(localStorage.getItem('system').indexOf('Symbia') == 0){
            var obj = {};
            processingData.drawData.forEach((val,index) => {
              if(val == undefined){
                processingData.drawData.splice(index, 1)
              }
            })
            processingData.drawData = processingData.drawData.reduce(function(item, next) {
              obj[next.code] ? '' : obj[next.code] = true && item.push(next);
              return item;
            }, []);
          }
          processingData?.layoutSchemeExports.forEach((ele) => {
            ele.mainDeviceExportData.forEach((item) => {
              if (item.isUse == true) {
                ele.deviceExportData.push(item)
              }
            })
          })
          this.toast.clear()
          this.status = 'plan'
          this.$store.commit('changeStep', this.status)
          localStorage.setItem('currentStatus', this.status)
          this.fanganjson = processingData
          sessionStorage.setItem('fanganjson', JSON.stringify(this.fanganjson))
          console.log(this.fanganjson)
          // 方案页面初始化默认选第一个
          this.choosePlan(0)
        } else {
          clearInterval(this.timer3)
          this.toast.clear()
          Dialog.alert({
            message: res.data.message
          }).then(() => {
            // on close
            this.$bus.$emit('goBack') //临时
            //   this.$router.push({ name: "Testing" });
          })
        }
      })
    },
    planNextStep() {
      this.status = 'adjustment'
      this.$store.commit('changeStep', this.status)
      localStorage.setItem('currentStatus', this.status)
      // this.updataAutoLayout()
      console.log(this.fanganjson.drawData, '8888888885555555555555588888888888888')
    },
  updataAutoLayout() {
      this.toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      let getDeviceTypeResult = JSON.parse(sessionStorage.getItem('setDeviceTypeResult'))
      getDeviceTypeResult.forEach((val,index) => {
        if(val.name === '诊断床，33°旋转带ICC和AQC' || val.name === '左手位'){
          if(this.selectLeftRight === 1){
            this.dataObj.examinationCouchName = val.name
            this.dataObj.examinationCouchId = val.id
          }
        }else if(val.name === '诊断床，33°旋转不带ICC和AQC' || val.name === '右手位'){
          if(this.selectLeftRight === 2){
            this.dataObj.examinationCouchName = val.name
            this.dataObj.examinationCouchId = val.id
          }
        }
      })
      let second = 120
      this.timer4 = setInterval(() => {
        second--
        if (!second) {
          clearInterval(this.timer4)
          this.toast.clear()
          Dialog.alert({
            message: '服务器超时,请重试!'
          })
        }
      }, 1000)
      this.$set(this.dataObj, 'Update', true)
      this.$set(
        this.dataObj,
        'DeviceName',
        this.dataObj.extJson.mainDeviceExportData[0].deviceName
      )
      this.dataObj.DeviceName = this.dataObj.examinationCouchName
      console.log(this.dataObj.extJson, '99999999999999999')
      this.$set(
        this.dataObj,
        'IsBig',
        this.dataObj.extJson.mainDeviceExportData[0].isBigArea
      )
      this.$set(
        this.dataObj,
        'Angle',
       this.gantryAngle
      )
       this.$set(
        this.dataObj,
        'Center_X',
        this.dataObj.extJson.mainDeviceExportData[0].point.x
      )
      this.$set(
        this.dataObj,
        'Center_Y',
        this.dataObj.extJson.mainDeviceExportData[0].point.y
      )
      // this.$set(
      //   this.dataObj,
      //   'Center_X',
      //   this.jijiax
      // )
      // this.$set(
      //   this.dataObj,
      //   'Center_Y',
      //   this.jijiay
      // )
      return new Promise((resolve,reject) => {
         this.demo(this.dataObj)
        AutoLayout(this.dataObj).then((res) => {
          if (res.data.success) {
            console.log(res, '******************res')
            clearInterval(this.timer4)
            let processingData = res.data.data
            // processingData.drawData =this.fanganjson.drawData
            processingData.layoutSchemeExports.forEach((ele,index) => {
              ele.mainDeviceExportData.forEach((item) => {
                if (item.isUse == true) {
                  ele.deviceExportData.push(item)
                }
              })
            })
            this.toast.clear()
            this.fanganjson.layoutSchemeExports[this.planIndex] =
              processingData.layoutSchemeExports[0]
            // this.fanganjson.layoutSchemeExports[this.planIndex].deviceExportData[0].point.x=353
              this.choosePlan(this.planIndex)
          } else {
            clearInterval(this.timer4)
            this.toast.clear()
          }
          resolve(res)
        })
      })
      // AutoLayout(this.dataObj).then((res) => {
      //   console.log(this.dataObj,'333222222222222');
      //   if (res.data.success) {
      //     let processingData = res.data.data
      //     // processingData.drawData =this.fanganjson.drawData
      //     processingData.layoutSchemeExports.forEach((ele) => {
      //       ele.mainDeviceExportData.forEach((item) => {
      //         if (item.isUse == true) {
      //           ele.deviceExportData.push(item)
      //         }
      //       })
      //     })
      //     clearInterval(this.timer4)
      //     this.toast.clear()
      //     // console.log("======",this.fanganjson.layoutSchemeExports[this.planIndex],processingData.layoutSchemeExports[0])
      //     this.fanganjson.layoutSchemeExports[this.planIndex] =
      //       processingData.layoutSchemeExports[0]
      //     // this.fanganjson.layoutSchemeExports[this.planIndex].deviceExportData[0].point.x=353
      //     this.choosePlan(this.planIndex)
      //   } else {
      //     clearInterval(this.timer4)
      //     this.toast.clear()
      //   }
      // })
    },
    async adjustmentNextStep() {
      console.log('adjustmentNextStep ---- danger')
      console.log(this.fanganjson.layoutSchemeExports[this.planIndex].tips)
      // 微调的下一步调取刷新按钮
      Promise.all([
        // await this.updataAutoLayout(),
        await this.updataAutoLayout(),
      ]).then((res) => {
         this.$nextTick(() => {
          this.status = 'danger'
          this.$store.commit('changeStep', this.status)
          localStorage.setItem('currentStatus', this.status)
          this.dataObj.dragAlarmList = {}
          if (
            !this.fanganjson.layoutSchemeExports[this.planIndex].tips.includes(
              ...this.dataObj.paramsAlarmList
            )
          ) {
            this.fanganjson.layoutSchemeExports[this.planIndex].tips.push(
              ...this.dataObj.paramsAlarmList
            )
          }
          this.fanganjson.layoutSchemeExports[this.planIndex].tips.forEach(
            (ele, index) => {
              console.log(ele, 'ele--------------')
              if(ele !== null && ele.message !== '' && ele.message === '视线不佳'){
                ele.message = '由于机房限制,该布局控制室医生观察视线不佳'
              }
              this.dataObj.dragAlarmList[index + 1] = ele
              this.fengxianmsg[ele.message] = index + 1
              this.makeNum(
                index + 1,
                1,
                this.bianjie[0] + ele.point.x * this.beishu,
                this.bianjie[1] + ele.point.y * this.beishu
              )
            }
          )
         })
      })

      
      // this.status = 'danger'
      // this.$store.commit('changeStep', this.status)
      // localStorage.setItem('currentStatus', this.status)
      // this.dataObj.dragAlarmList = {}
      // if (
      //   !this.fanganjson.layoutSchemeExports[this.planIndex].tips.includes(
      //     ...this.dataObj.paramsAlarmList
      //   )
      // ) {
      //   console.log('我进来了-------------')
      //   this.fanganjson.layoutSchemeExports[this.planIndex].tips.push(
      //     ...this.dataObj.paramsAlarmList
      //   )
      // }
      // console.log(this.fanganjson.layoutSchemeExports[this.planIndex].tips, '**************')
      // this.fanganjson.layoutSchemeExports[this.planIndex].tips.forEach(
      //   (ele, index) => {
      //     this.dataObj.dragAlarmList[index + 1] = ele
      //     this.fengxianmsg[ele.message] = index + 1
      //     this.makeNum(
      //       index + 1,
      //       1,
      //       this.bianjie[0] + ele.point.x * this.beishu,
      //       this.bianjie[1] + ele.point.y * this.beishu
      //     )
      //   }
      // )
      console.log(this.dataObj, '-------------')
    },
    addDanger() {
      this.$refs.riskRef.openPopup()
    },
    chooseRisk(item, index) {
      this.riskIndex = index
      this.riskItem = item
    },
    riskConfirm() {
      this.$refs.riskRef.closePopup()
      this.riskIndex = -1
      if (this.addRiskValue != '') {
        this.riskItem = {
          point: {
            x: 0,
            y: 0
          },
          message: this.addRiskValue
        }
        if (this.fengxianmsg[this.addRiskValue] != undefined)
          this.fengxianmsg[this.addRiskValue] = 0
      }
      let has = false
      for (let i in this.dataObj.dragAlarmList) {
        if (this.riskItem.message == this.dataObj.dragAlarmList[i].message) {
          has = true
          break
        }
      }
      if (!has) {
        let num = 1
        if (Object.keys(this.dataObj.dragAlarmList).length > 0)
          num =
            parseInt(
              Object.keys(this.dataObj.dragAlarmList)[
                Object.keys(this.dataObj.dragAlarmList).length - 1
              ]
            ) + 1
        this.dataObj.dragAlarmList[num] = this.riskItem
        this.fengxianmsg[this.riskItem.message] = num
        this.makeNum(num, 0)
      } else {
        Toast('该风险已添加')
      }
      this.addRiskValue = ''
      console.log(this.dataObj.dragAlarmList, '风险提示代码----------')
    },
    riskCancel() {
      this.$refs.riskRef.closePopup()
      this.riskIndex = -1
    },
    removeDanger(item, index) {
      console.log(
        this.fengxianmsg,
        this.domTxtGrap,
        item,
        index,
        this.dataObj.dragAlarmList
      )
      if (this.fengxianmsg[item.message] != undefined) {
        this.app.stage.removeChild(
          this.domTxtGrap[this.fengxianmsg[item.message]]
        )
        this.domTxtGrap[this.fengxianmsg[item.message]].destroy({
          children: true
        })
        delete this.domTxtGrap[this.fengxianmsg[item.message]]
        delete this.fengxianmsg[item.message]
      }
      this.$delete(this.dataObj.dragAlarmList, index)
      let autonum = 1
      for (let i in this.domTxtGrap) {
        if (i != autonum) {
          this.domTxtGrap[autonum] = this.domTxtGrap[i]
          for (let k in this.fengxianmsg) {
            if (this.fengxianmsg[k] == i) {
              this.fengxianmsg[k] = autonum
            }
          }
          this.domTxtGrap[autonum].text = '#' + autonum

          delete this.domTxtGrap[i]
          this.dataObj.dragAlarmList[autonum] = this.dataObj.dragAlarmList[i]
          delete this.dataObj.dragAlarmList[i]
        }
        autonum++
      }
    },
    dangerNextStep() {
      this.status = 'angle'
      this.$store.commit('changeStep', this.status)
      localStorage.setItem('currentStatus', this.status)
      var values = []
      for (var property in this.dataObj.dragAlarmList)
        values.push(this.dataObj.dragAlarmList[property])
      this.dataObj.extJson.tips = values
    },
    shunRotate() {
      this.dataObj.tuzhiRotation += 90
      if (this.dataObj.tuzhiRotation >= 360) {
        this.dataObj.tuzhiRotation = 0
      }
      if (
        this.dataObj.tuzhiRotation == 90 ||
        this.dataObj.tuzhiRotation == 270 ||
        this.dataObj.tuzhiRotation == -90 ||
        this.dataObj.tuzhiRotation == -270
      ) {
        this.dataObj.tuzhiScale = document.querySelector(".pixi").offsetWidth/document.getElementById("pixi").offsetHeight;
      } else {
        this.dataObj.tuzhiScale = 1;
      }
    },
    niRotate() {
      this.dataObj.tuzhiRotation -= 90
      if (this.dataObj.tuzhiRotation <= -360) {
        this.dataObj.tuzhiRotation = 0
      }
      if (
        this.dataObj.tuzhiRotation == 90 ||
        this.dataObj.tuzhiRotation == 270 ||
        this.dataObj.tuzhiRotation == -90 ||
        this.dataObj.tuzhiRotation == -270
      ) {
        this.dataObj.tuzhiScale = document.querySelector(".pixi").offsetWidth/document.getElementById("pixi").offsetHeight;
      } else {
        this.dataObj.tuzhiScale = 1;
      }
    },
    angleNextStep() {
      let second = 120
      this.timer5 = setInterval(() => {
        second--
        if (!second && this.flag == true) {
          clearInterval(this.timer5)
          clearInterval(this.timer)
          this.toast.clear()
          Dialog.alert({
            message: '服务器超时,请重试!'
          }).then(() => {
            this.$bus.$emit('goBack')
          })
        }
      }, 1000)
      // this.statusdrawing = true
      this.status = 'drawing'
      this.$store.commit('changeStep', this.status)
      localStorage.setItem('currentStatus', this.status)
      if (sessionStorage.getItem('isHaveKong') == 'false') {
        this.dataObj.controlRoomForm.location = 0
        this.dataObj.controlRoomForm.length = 0
        this.dataObj.controlRoomForm.width = 0
        this.dataObj.controlRoomForm.doorPosition = 0
        this.dataObj.controlRoomForm.doorType = 0
        this.dataObj.controlRoomForm.doorLocation = 0
        this.dataObj.controlRoomForm.doorWidth = 0
        this.dataObj.controlRoomForm.doorDirection = 0
      }
      this.dataObj.extJson.checkDimPoints = this.newField
      let taskJson = {}
      taskJson = JSON.parse(JSON.stringify(this.dataObj))
      taskJson.extJson = JSON.stringify(taskJson.extJson)
      this.demo(taskJson)
      AddCadTask(taskJson).then((res) => {
        this.taskId = res.data.data
        this.timer = setInterval(() => {
          this.loading()
        }, 500)
        this.timer1 = setInterval(() => {
          this.getTaskStatus()
        }, 3000)
      })
    },
    makemen(obj) {
      console.log(obj, '----------')
      var qianghou =
        this.dataObj.machineRoomForm.wallThickness +
        Number(this.dataObj.machineRoomForm.decorateThickness)

      if (obj.weizhi == 3) {
        if (obj.type == 0) {
          var flg = 'top'
          if (obj.name == 'kongzhimen') {
            if (
              (this.bianjie[7] - this.bianjie[5]) / 2 >
              (obj.y -
                this.dataObj.machineRoomForm.width -
                qianghou * 2 +
                obj.width / 2) *
                this.beishu
            ) {
              flg = 'top'
            } else {
              flg = 'bottom'
            }
            var qianghou2 = this.dataObj.machineRoomForm.wallThickness
          } else {
            if (
              (this.bianjie[3] - this.bianjie[1]) / 2 >
              (obj.y + obj.width / 2) * this.beishu
            ) {
              flg = 'top'
            } else {
              flg = 'bottom'
            }
            var qianghou2 = qianghou
          }
          var bhtongdao = [
            {
              style: 2,
              fill: '0xffffff',
              rect: {
                x: obj.x,
                y: obj.y,
                w: qianghou2,
                h: obj.width
              }
            },
            {
              style: 0,
              line: {
                x: obj.x,
                y: obj.y,
                x2: obj.x + qianghou2,
                y2: obj.y
              }
            },
            {
              style: 0,
              line: {
                x: obj.x,
                y: obj.y + obj.width,
                x2: obj.x + qianghou2,
                y2: obj.y + obj.width
              }
            }
          ]
          this.bhtongdao = bhtongdao
          if (obj.chaoxiang == 1) {
            bhtongdao.push({
              style: 1,
              line: {
                x: obj.x,
                y: obj.y,
                x2: obj.x,
                y2: obj.y + obj.width
              }
            })
            if (flg == 'top') {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x - obj.width,
                  y2: obj.y
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y,
                  radius: obj.width,
                  startAngle: (90 * Math.PI) / 180,
                  endAngle: (180 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x - obj.width,
                  y2: obj.y
                }
              })
            } else {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x - obj.width,
                  y2: obj.y + obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  radius: obj.width,
                  startAngle: (180 * Math.PI) / 180,
                  endAngle: (270 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x - obj.width,
                  y2: obj.y + obj.width
                }
              })
            }
          } else if (obj.chaoxiang == 0) {
            bhtongdao.push({
              style: 1,
              line: {
                x: obj.x + qianghou2,
                y: obj.y,
                x2: obj.x + qianghou2,
                y2: obj.y + obj.width
              }
            })
            if (flg == 'top') {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x + qianghou2,
                  y: obj.y,
                  x2: obj.x + qianghou2 + obj.width,
                  y2: obj.y
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x + qianghou2,
                  y: obj.y,
                  radius: obj.width,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (90 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x + qianghou2,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou2 + obj.width,
                  y2: obj.y
                }
              })
            } else {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x + qianghou2,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou2 + obj.width,
                  y2: obj.y + obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x + qianghou2,
                  y: obj.y + obj.width,
                  radius: obj.width,
                  startAngle: (270 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x + qianghou2,
                  y: obj.y,
                  x2: obj.x + qianghou2 + obj.width,
                  y2: obj.y + obj.width
                }
              })
            }
          }
        } else if (obj.type == 2) {
          if (obj.huadong == 1 || obj.huadong == 2) {
            if (obj.chaoxiang == 0) {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: qianghou,
                    h: obj.width
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x + qianghou,
                    y2: obj.y
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y + obj.width,
                    x2: obj.x + qianghou,
                    y2: obj.y + obj.width
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x + qianghou,
                    y: obj.y + obj.width / 2,
                    w: 15,
                    h: obj.width
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            } else {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: qianghou,
                    h: obj.width
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x + qianghou,
                    y2: obj.y
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y + obj.width,
                    x2: obj.x + qianghou,
                    y2: obj.y + obj.width
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x - 15,
                    y: obj.y + obj.width / 2,
                    w: 15,
                    h: obj.width
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            }
          } else if (obj.huadong == 0 || obj.huadong == 3) {
            if (obj.chaoxiang == 0) {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: qianghou,
                    h: obj.width
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x + qianghou,
                    y2: obj.y
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y + obj.width,
                    x2: obj.x + qianghou,
                    y2: obj.y + obj.width
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x + qianghou,
                    y: obj.y - obj.width / 2,
                    w: 15,
                    h: obj.width
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            } else {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: qianghou,
                    h: obj.width
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x + qianghou,
                    y2: obj.y
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y + obj.width,
                    x2: obj.x + qianghou,
                    y2: obj.y + obj.width
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x - 15,
                    y: obj.y - obj.width / 2,
                    w: 15,
                    h: obj.width
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            }
          }
        } else if (obj.type == 1) {
          if (obj.chaoxiang == 1) {
            var bhtongdao = [
              {
                style: 2,
                fill: '0xffffff',
                rect: {
                  x: obj.x,
                  y: obj.y,
                  w: qianghou,
                  h: obj.width
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x + qianghou,
                  y2: obj.y
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x - obj.width / 2,
                  y2: obj.y
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x - obj.width / 2,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y,
                  radius: obj.width / 2,
                  startAngle: (90 * Math.PI) / 180,
                  endAngle: (180 * Math.PI) / 180
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  radius: obj.width / 2,
                  startAngle: (180 * Math.PI) / 180,
                  endAngle: (270 * Math.PI) / 180
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x - obj.width / 2,
                  y: obj.y,
                  x2: obj.x - obj.width / 2,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x - obj.width / 2,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y + obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width / 2,
                  x2: obj.x - obj.width / 2,
                  y2: obj.y + obj.width
                }
              }
            ]
          this.bhtongdao = bhtongdao
          } else if (obj.chaoxiang == 0) {
            var bhtongdao = [
              {
                style: 2,
                fill: '0xffffff',
                rect: {
                  x: obj.x,
                  y: obj.y,
                  w: qianghou,
                  h: obj.width
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x + qianghou,
                  y2: obj.y
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y,
                  x2: obj.x + qianghou,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x + qianghou,
                  y: obj.y,
                  radius: obj.width / 2,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (90 * Math.PI) / 180
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x + qianghou,
                  y: obj.y + obj.width,
                  radius: obj.width / 2,
                  startAngle: (270 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + qianghou + obj.width / 2,
                  y: obj.y,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y + obj.width / 2,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y + obj.width / 2,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y + obj.width
                }
              }
            ]
          this.bhtongdao = bhtongdao
          }
        }
      } else if (obj.weizhi == 0) {
        if (obj.type == 0) {
          var bhtongdao = [
            {
              style: 2,
              fill: '0xffffff',
              rect: {
                x: obj.x,
                y: obj.y,
                w: obj.width,
                h: qianghou
              }
            },
            {
              style: 0,
              line: {
                x: obj.x,
                y: obj.y,
                x2: obj.x,
                y2: obj.y + qianghou
              }
            },
            {
              style: 0,
              line: {
                x: obj.x + obj.width,
                y: obj.y,
                x2: obj.x + obj.width,
                y2: obj.y + qianghou
              }
            }
          ]
          this.bhtongdao = bhtongdao
          if (obj.chaoxiang == 1) {
            bhtongdao.push({
              style: 1,
              line: {
                x: obj.x,
                y: obj.y + qianghou,
                x2: obj.x + obj.width,
                y2: obj.y + qianghou
              }
            })
            if (
              (this.bianjie[2] - this.bianjie[0]) / 2 >
              (obj.x + obj.width / 2) * this.beishu
            ) {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  x2: obj.x,
                  y2: obj.y + qianghou + obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  radius: obj.width,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (90 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou,
                  x2: obj.x,
                  y2: obj.y + qianghou + obj.width
                }
              })
            } else {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou + obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou,
                  radius: obj.width,
                  startAngle: (90 * Math.PI) / 180,
                  endAngle: (180 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou + obj.width
                }
              })
            }
          } else if (obj.chaoxiang == 0) {
            bhtongdao.push({
              style: 1,
              line: {
                x: obj.x,
                y: obj.y,
                x2: obj.x + obj.width,
                y2: obj.y
              }
            })
            if (
              (this.bianjie[2] - this.bianjie[0]) / 2 >
              (obj.x + obj.width / 2) * this.beishu
            ) {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y - obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y,
                  radius: obj.width,
                  startAngle: (270 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y - obj.width,
                  x2: obj.x + obj.width,
                  y2: obj.y
                }
              })
            } else {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  radius: obj.width,
                  startAngle: (180 * Math.PI) / 180,
                  endAngle: (270 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y - obj.width,
                  x2: obj.x,
                  y2: obj.y
                }
              })
            }
          }
        } else if (obj.type == 2) {
          if (obj.huadong == 0 || obj.huadong == 2) {
            if (obj.chaoxiang == 0) {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: obj.width,
                    h: qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    x2: obj.x + obj.width,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x + obj.width / 2,
                    y: obj.y - 15,
                    w: obj.width,
                    h: 15
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            } else {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: obj.width,
                    h: qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    x2: obj.x + obj.width,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x + obj.width / 2,
                    y: obj.y + qianghou,
                    w: obj.width,
                    h: 15
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            }
          } else if (obj.huadong == 1 || obj.huadong == 3) {
            if (obj.chaoxiang == 0) {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: obj.width,
                    h: qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    x2: obj.x + obj.width,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x - obj.width / 2,
                    y: obj.y - 15,
                    w: obj.width,
                    h: 15
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            } else {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: obj.width,
                    h: qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    x2: obj.x + obj.width,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x - obj.width / 2,
                    y: obj.y + qianghou,
                    w: obj.width,
                    h: 15
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            }
          }
        } else if (obj.type == 1) {
          if (obj.chaoxiang == 1) {
            var bhtongdao = [
              {
                style: 2,
                fill: '0xffffff',
                rect: {
                  x: obj.x,
                  y: obj.y,
                  w: obj.width,
                  h: qianghou
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  x2: obj.x,
                  y2: obj.y + qianghou + obj.width / 2
                }
              },

              {
                style: 1,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou + obj.width / 2
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou,
                  radius: obj.width / 2,
                  startAngle: (90 * Math.PI) / 180,
                  endAngle: (180 * Math.PI) / 180
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  radius: obj.width / 2,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (90 * Math.PI) / 180
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou + obj.width / 2,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou + obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + obj.width / 2,
                  y: obj.y + qianghou,
                  x2: obj.x,
                  y2: obj.y + qianghou + obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + obj.width / 2,
                  y: obj.y + qianghou,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou + obj.width / 2
                }
              }
            ]
          this.bhtongdao = bhtongdao
          } else if (obj.chaoxiang == 0) {
            var bhtongdao = [
              {
                style: 2,
                fill: '0xffffff',
                rect: {
                  x: obj.x,
                  y: obj.y,
                  w: obj.width,
                  h: qianghou
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y - obj.width / 2
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y,
                  radius: obj.width / 2,
                  startAngle: (270 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width / 2
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  radius: obj.width / 2,
                  startAngle: (180 * Math.PI) / 180,
                  endAngle: (270 * Math.PI) / 180
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y - obj.width / 2,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + obj.width / 2,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y - obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + obj.width / 2,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width / 2
                }
              }
            ]
          this.bhtongdao = bhtongdao
          }
        }
      } else if (obj.weizhi == 2) {
        if (obj.type == 0) {
          var flg = 'top'
          if (obj.name == 'kongzhimen') {
            if (
              (this.bianjie[7] - this.bianjie[5]) / 2 >
              (obj.y -
                this.dataObj.machineRoomForm.width -
                qianghou * 2 +
                obj.width / 2) *
                this.beishu
            ) {
              flg = 'top'
            } else {
              flg = 'bottom'
            }
            var qianghou2 = this.dataObj.machineRoomForm.wallThickness
          } else {
            if (
              (this.bianjie[3] - this.bianjie[1]) / 2 >
              (obj.y + obj.width / 2) * this.beishu
            ) {
              flg = 'top'
            } else {
              flg = 'bottom'
            }
            var qianghou2 = qianghou
          }
          var bhtongdao = [
            {
              style: 2,
              fill: '0xffffff',
              rect: {
                x: obj.x,
                y: obj.y,
                w: qianghou2,
                h: obj.width
              }
            },
            {
              style: 0,
              line: {
                x: obj.x,
                y: obj.y,
                x2: obj.x + qianghou2,
                y2: obj.y
              }
            },
            {
              style: 0,
              line: {
                x: obj.x,
                y: obj.y + obj.width,
                x2: obj.x + qianghou2,
                y2: obj.y + obj.width
              }
            }
          ]
          this.bhtongdao = bhtongdao
          if (obj.chaoxiang == 1) {
            bhtongdao.push({
              style: 1,
              line: {
                x: obj.x + qianghou2,
                y: obj.y,
                x2: obj.x + qianghou2,
                y2: obj.y + obj.width
              }
            })
            if (flg == 'top') {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x + qianghou2,
                  y: obj.y,
                  x2: obj.x + qianghou2 + obj.width,
                  y2: obj.y
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x + qianghou2,
                  y: obj.y,
                  radius: obj.width,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (90 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x + qianghou2,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou2 + obj.width,
                  y2: obj.y
                }
              })
            } else {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x + qianghou2,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou2 + obj.width,
                  y2: obj.y + obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x + qianghou2,
                  y: obj.y + obj.width,
                  radius: obj.width,
                  startAngle: (270 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x + qianghou2,
                  y: obj.y,
                  x2: obj.x + qianghou2 + obj.width,
                  y2: obj.y + obj.width
                }
              })
            }
          } else if (obj.chaoxiang == 0) {
            bhtongdao.push({
              style: 1,
              line: {
                x: obj.x,
                y: obj.y,
                x2: obj.x,
                y2: obj.y + obj.width
              }
            })
            if (flg == 'top') {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x - obj.width,
                  y2: obj.y
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y,
                  radius: obj.width,
                  startAngle: (90 * Math.PI) / 180,
                  endAngle: (180 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x - obj.width,
                  y2: obj.y
                }
              })
            } else {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x - obj.width,
                  y2: obj.y + obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  radius: obj.width,
                  startAngle: (180 * Math.PI) / 180,
                  endAngle: (270 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x - obj.width,
                  y2: obj.y + obj.width
                }
              })
            }
          }
        } else if (obj.type == 2) {
          if (obj.huadong == 0 || obj.huadong == 2) {
            if (obj.chaoxiang == 0) {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: qianghou,
                    h: obj.width
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x + qianghou,
                    y2: obj.y
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y + obj.width,
                    x2: obj.x + qianghou,
                    y2: obj.y + obj.width
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x - 15,
                    y: obj.y - obj.width / 2,
                    w: 15,
                    h: obj.width
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            } else {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: qianghou,
                    h: obj.width
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x + qianghou,
                    y2: obj.y
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y + obj.width,
                    x2: obj.x + qianghou,
                    y2: obj.y + obj.width
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x + qianghou,
                    y: obj.y - obj.width / 2,
                    w: 15,
                    h: obj.width
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            }
          } else if (obj.huadong == 1 || obj.huadong == 3) {
            if (obj.chaoxiang == 0) {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: qianghou,
                    h: obj.width
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x + qianghou,
                    y2: obj.y
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y + obj.width,
                    x2: obj.x + qianghou,
                    y2: obj.y + obj.width
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x - 15,
                    y: obj.y + obj.width / 2,
                    w: 15,
                    h: obj.width
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            } else {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: qianghou,
                    h: obj.width
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x + qianghou,
                    y2: obj.y
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y + obj.width,
                    x2: obj.x + qianghou,
                    y2: obj.y + obj.width
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x + qianghou,
                    y: obj.y + obj.width / 2,
                    w: 15,
                    h: obj.width
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            }
          }
        } else if (obj.type == 1) {
          if (obj.chaoxiang == 1) {
            var bhtongdao = [
              {
                style: 2,
                fill: '0xffffff',
                rect: {
                  x: obj.x,
                  y: obj.y,
                  w: qianghou,
                  h: obj.width
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x + qianghou,
                  y2: obj.y
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y,
                  x2: obj.x + qianghou,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x + qianghou,
                  y: obj.y,
                  radius: obj.width / 2,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (90 * Math.PI) / 180
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x + qianghou,
                  y: obj.y + obj.width,
                  radius: obj.width / 2,
                  startAngle: (270 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + qianghou + obj.width / 2,
                  y: obj.y,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y + obj.width / 2,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + qianghou,
                  y: obj.y + obj.width / 2,
                  x2: obj.x + qianghou + obj.width / 2,
                  y2: obj.y + obj.width
                }
              }
            ]
          this.bhtongdao = bhtongdao
          } else if (obj.chaoxiang == 0) {
            var bhtongdao = [
              {
                style: 2,
                fill: '0xffffff',
                rect: {
                  x: obj.x,
                  y: obj.y,
                  w: qianghou,
                  h: obj.width
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x + qianghou,
                  y2: obj.y
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x + qianghou,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x - obj.width / 2,
                  y2: obj.y
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  x2: obj.x - obj.width / 2,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y,
                  radius: obj.width / 2,
                  startAngle: (90 * Math.PI) / 180,
                  endAngle: (180 * Math.PI) / 180
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y + obj.width,
                  radius: obj.width / 2,
                  startAngle: (180 * Math.PI) / 180,
                  endAngle: (270 * Math.PI) / 180
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x - obj.width / 2,
                  y: obj.y,
                  x2: obj.x - obj.width / 2,
                  y2: obj.y + obj.width
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x - obj.width / 2,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y + obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y + obj.width / 2,
                  x2: obj.x - obj.width / 2,
                  y2: obj.y + obj.width
                }
              }
            ]
          this.bhtongdao = bhtongdao
          }
        }
      } else if (obj.weizhi == 1) {
        if (obj.type == 0) {
          var flg = 'left'
          if (obj.name == 'kongzhimen') {
            if (
              (this.bianjie[6] - this.bianjie[4]) / 2 >
              (obj.x + obj.width / 2) * this.beishu
            ) {
              flg = 'left'
            } else {
              flg = 'right'
            }
            var qianghou2 = this.dataObj.machineRoomForm.wallThickness
          } else {
            if (
              (this.bianjie[2] - this.bianjie[0]) / 2 >
              (obj.x + obj.width / 2) * this.beishu
            ) {
              flg = 'left'
            } else {
              flg = 'right'
            }
            var qianghou2 = qianghou
          }
          var bhtongdao = [
            {
              style: 2,
              fill: '0xffffff',
              rect: {
                x: obj.x,
                y: obj.y,
                w: obj.width,
                h: qianghou2
              }
            },
            {
              style: 0,
              line: {
                x: obj.x,
                y: obj.y,
                x2: obj.x,
                y2: obj.y + qianghou2
              }
            },
            {
              style: 0,
              line: {
                x: obj.x + obj.width,
                y: obj.y,
                x2: obj.x + obj.width,
                y2: obj.y + qianghou2
              }
            }
          ]
          this.bhtongdao = bhtongdao
          if (obj.chaoxiang == 1) {
            bhtongdao.push({
              style: 1,
              line: {
                x: obj.x,
                y: obj.y,
                x2: obj.x + obj.width,
                y2: obj.y
              }
            })
            if (flg == 'left') {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y - obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y,
                  radius: obj.width,
                  startAngle: (270 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y - obj.width
                }
              })
            } else {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width
                }
              })
              if(obj.name == 'jishimen' && (this.dataObj.machineRoomForm.length / 2) <  obj.x || obj.name == "kongzhimen" && (this.dataObj.controlRoomForm.length / 2) <  obj.x){
                console.log('竖向左')
                bhtongdao.push({
                  style: 1,
                  arc: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    radius: obj.width,
                    startAngle: (180 * Math.PI) / 180,
                    endAngle: (270 * Math.PI) / 180
                  }
                })
              }else if(obj.name == 'jishimen' && (this.dataObj.machineRoomForm.length / 2) >  obj.x || obj.name == "kongzhimen" && (this.dataObj.controlRoomForm.length / 2) >  obj.x){
                 console.log(obj, '竖向右')
                bhtongdao.push({
                  style: 1,
                  arc: {
                    x: obj.x + obj.width,
                    // x: obj.x - obj.width,
                    y: obj.y,
                    radius: obj.width,
                    startAngle: ((270 * Math.PI) / 180),
                    endAngle: -((360 * Math.PI) / 360)
                  }
                })
              }
              // bhtongdao.push({
              //   style: 1,
              //   arc: {
              //     x: obj.x + obj.width - obj.width,
              //     y: obj.y,
              //     radius: obj.width,
              //     startAngle: (270 * Math.PI) / 180,
              //     endAngle: (360 * Math.PI) / 180
              //     // startAngle: (180 * Math.PI) / 180,
              //     // endAngle: (270 * Math.PI) / 180
              //   }
              // })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width
                }
              })
              // bhtongdao.push({
              //   style: 1,
              //   line: {
              //     x: obj.x + obj.width,
              //     y: obj.y,
              //     x2: obj.x + obj.width,
              //     y2: obj.y - obj.width
              //   }
              // })
              // bhtongdao.push({
              //   style: 1,
              //   arc: {
              //     x: obj.x + obj.width,
              //     y: obj.y,
              //     radius: obj.width,
              //     startAngle: (180 * Math.PI) / 180,
              //     endAngle: (270 * Math.PI) / 180
              //   }
              // })
              // bhtongdao.push({
              //   style: 2,
              //   line: {
              //     x: obj.x,
              //     y: obj.y,
              //     x2: obj.x + obj.width,
              //     y2: obj.y - obj.width
              //   }
              // })
            }
          } else if (obj.chaoxiang == 0) {
            bhtongdao.push({
              style: 1,
              line: {
                x: obj.x,
                y: obj.y + qianghou2,
                x2: obj.x + obj.width,
                y2: obj.y + qianghou2
              }
            })
            if (flg == 'left') {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou2,
                  x2: obj.x,
                  y2: obj.y + qianghou2 + obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y + qianghou2,
                  radius: obj.width,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (90 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou2,
                  x2: obj.x,
                  y2: obj.y + qianghou2 + obj.width
                }
              })
            } else {
              bhtongdao.push({
                style: 1,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou2,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou2 + obj.width
                }
              })
              bhtongdao.push({
                style: 1,
                arc: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou,
                  radius: obj.width,
                  startAngle: (90 * Math.PI) / 180,
                  endAngle: (180 * Math.PI) / 180
                }
              })
              bhtongdao.push({
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou2,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou2 - obj.width
                }
              })
            }
          }
        } else if (obj.type == 1) {
          if (obj.chaoxiang == 1) {
            var bhtongdao = [
              {
                style: 2,
                fill: '0xffffff',
                rect: {
                  x: obj.x,
                  y: obj.y,
                  w: obj.width,
                  h: qianghou
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y - obj.width / 2
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y,
                  radius: obj.width / 2,
                  startAngle: (270 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width / 2
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  radius: obj.width / 2,
                  startAngle: (180 * Math.PI) / 180,
                  endAngle: (270 * Math.PI) / 180
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y - obj.width / 2,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + obj.width / 2,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y - obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + obj.width / 2,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y - obj.width / 2
                }
              }
            ]
          this.bhtongdao = bhtongdao
          } else if (obj.chaoxiang == 0) {
            var bhtongdao = [
              {
                style: 2,
                fill: '0xffffff',
                rect: {
                  x: obj.x,
                  y: obj.y,
                  w: obj.width,
                  h: qianghou
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x,
                  y: obj.y,
                  x2: obj.x,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 0,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  x2: obj.x,
                  y2: obj.y + qianghou + obj.width / 2
                }
              },

              {
                style: 1,
                line: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou + obj.width / 2
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x + obj.width,
                  y: obj.y + qianghou,
                  radius: obj.width / 2,
                  startAngle: (90 * Math.PI) / 180,
                  endAngle: (180 * Math.PI) / 180
                }
              },
              {
                style: 1,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou
                }
              },
              {
                style: 1,
                arc: {
                  x: obj.x,
                  y: obj.y + qianghou,
                  radius: obj.width / 2,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (90 * Math.PI) / 180
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x,
                  y: obj.y + qianghou + obj.width / 2,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou + obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + obj.width / 2,
                  y: obj.y + qianghou,
                  x2: obj.x,
                  y2: obj.y + qianghou + obj.width / 2
                }
              },
              {
                style: 2,
                line: {
                  x: obj.x + obj.width / 2,
                  y: obj.y + qianghou,
                  x2: obj.x + obj.width,
                  y2: obj.y + qianghou + obj.width / 2
                }
              }
            ]
          this.bhtongdao = bhtongdao
          }
        } else if (obj.type == 2) {
          if (obj.huadong == 0 || obj.huadong == 2) {
            if (obj.chaoxiang == 0) {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: obj.width,
                    h: qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    x2: obj.x + obj.width,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x + obj.width / 2,
                    y: obj.y + qianghou,
                    w: obj.width,
                    h: 15
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            } else {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: obj.width,
                    h: qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    x2: obj.x + obj.width,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x + obj.width / 2,
                    y: obj.y - 15,
                    w: obj.width,
                    h: 15
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            }
          } else if (obj.huadong == 1 || obj.huadong == 3) {
            if (obj.chaoxiang == 0) {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: obj.width,
                    h: qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    x2: obj.x + obj.width,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x - obj.width / 2,
                    y: obj.y + qianghou,
                    w: obj.width,
                    h: 15
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            } else {
              var bhtongdao = [
                {
                  style: 2,
                  fill: '0xffffff',
                  rect: {
                    x: obj.x,
                    y: obj.y,
                    w: obj.width,
                    h: qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x,
                    y: obj.y,
                    x2: obj.x,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 0,
                  line: {
                    x: obj.x + obj.width,
                    y: obj.y,
                    x2: obj.x + obj.width,
                    y2: obj.y + qianghou
                  }
                },
                {
                  style: 1,
                  rect: {
                    x: obj.x - obj.width / 2,
                    y: obj.y - 15,
                    w: obj.width,
                    h: 15
                  }
                }
              ]
          this.bhtongdao = bhtongdao
            }
          }
        }
      }
      let jishimen_jianshao = 0
      if (obj.name == 'jishimen'){
        jishimen_jianshao = 100
        if(this.dataObj.controlRoomForm.length < 520){
          jishimen_jianshao = this.dataObj.controlRoomForm.width
        }
      }
      console.log(jishimen_jianshao, 'jishimen_jianshao------')
      // if (obj.name == 'jishimen') jishimen_jianshao = this.dataObj.controlRoomForm.width
      if (obj.weizhi == 0)
        this.makeBiaozhuJson(
          obj.name,
          obj.x,
          qianghou - Number(this.dataObj.machineRoomForm.decorateThickness),
          obj.x + obj.width,
          qianghou - Number(this.dataObj.machineRoomForm.decorateThickness),
          -1
        )
      if (obj.weizhi == 1) {
        if (obj.name == 'jifangmen') {
          this.makeBiaozhuJson(
            obj.name,
            obj.x,
            qianghou +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              this.dataObj.machineRoomForm.width,
            obj.x + obj.width,
            qianghou +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              this.dataObj.machineRoomForm.width,
            1
          )
        }
        if (obj.name != 'jifangmen') {
          this.makeBiaozhuJson(
            obj.name,
            obj.x,
            qianghou * 2 +
              this.dataObj.machineRoomForm.width +
              this.dataObj.controlRoomForm.width -
              jishimen_jianshao,
            obj.x + obj.width,
            qianghou * 2 +
              this.dataObj.machineRoomForm.width +
              this.dataObj.controlRoomForm.width -
              jishimen_jianshao,
            1
          )
        }
      }
      if (obj.weizhi == 2) {
        if (obj.name == 'jifangmen') {
          this.makeBiaozhuJson(
            obj.name,
            qianghou - Number(this.dataObj.machineRoomForm.decorateThickness),
            obj.y,
            qianghou - Number(this.dataObj.machineRoomForm.decorateThickness),
            obj.y + obj.width,
            -1
          )
        }
        if (obj.name == 'kongzhimen') {
          this.makeBiaozhuJson(
            obj.name,
            this.dataObj.controlRoomForm.location + qianghou2,
            obj.y,
            this.dataObj.controlRoomForm.location + qianghou2,
            obj.y + obj.width,
            -1
          )
        }
      }
      if (obj.weizhi == 3) {
        if (obj.name == 'jifangmen') {
          this.makeBiaozhuJson(
            obj.name,
            qianghou +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              this.dataObj.machineRoomForm.length,
            obj.y,
            qianghou +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              this.dataObj.machineRoomForm.length,
            obj.y + obj.width,
            1
          )
        }
        if (obj.name == 'kongzhimen') {
          this.makeBiaozhuJson(
            obj.name,
            qianghou2 +
              this.dataObj.controlRoomForm.location +
              this.dataObj.controlRoomForm.length,
            obj.y,
            qianghou2 +
              this.dataObj.controlRoomForm.location +
              this.dataObj.controlRoomForm.length,
            obj.y + obj.width,
            1
          )
        }
      }
      return bhtongdao
    },
    initbiaozhu() {
      if (Object.getOwnPropertyNames(this.domGrapBJ).length > 0) {
        for (let i in this.domGrapBJ) {
          this.app.stage.removeChild(this.domGrapBJ[i])
          this.domGrapBJ[i].destroy({
            children: true
          })
        }
        this.domGrapBJ = {}
      }
      if (Object.getOwnPropertyNames(this.domTxtGrapBJ).length > 0) {
        for (let i in this.domTxtGrapBJ) {
          this.app.stage.removeChild(this.domTxtGrapBJ[i])
        }
        this.domTxtGrapBJ = {}
      }
      for (let k in this.domJsonBJ) {
        this.makeBiaozhu(this.domJsonBJ[k])
      }
    },
    makeBiaozhu(data) {
      let k = data.name
      this.domGrapBJ[k] = new PIXI.Container()
      let sundom = new PIXI.Graphics()
      data.child.forEach((v) => {
        this.makeGraph(data.styles[v.style], v, sundom)
      })
      this.domGrapBJ[k].addChild(sundom)
      this.domGrapBJ[k].x = this.domJson['机房'].position.x
      this.domGrapBJ[k].y = this.domJson['机房'].position.y

      this.domGrapBJ[k].scale.x = this.beishu
      this.domGrapBJ[k].scale.y = this.beishu
      this.app.stage.addChild(this.domGrapBJ[k])

      this.domTxtGrapBJ[k] = new PIXI.Text(this.domTxtJsonBJ[k].num, {
        fontFamily: 'Arial',
        fontSize: this.zihao,
        fill: data.styles[this.domTxtJsonBJ[k].style].color,
        align: 'center'
      })
      this.domTxtGrapBJ[k].x =
        this.domTxtJsonBJ[k].x * this.beishu + this.domJson['机房'].position.x
      this.domTxtGrapBJ[k].y =
        this.domTxtJsonBJ[k].y * this.beishu + this.domJson['机房'].position.y
      // this.domTxtGrapBJ[k].scale.x = 0.3;
      // this.domTxtGrapBJ[k].scale.y = 0.3;
      this.domTxtGrapBJ[k].rotation =
        (this.domTxtJsonBJ[k].rotation / 180) * Math.PI
      this.app.stage.addChild(this.domTxtGrapBJ[k])
      let msg = '检查室净尺寸'
      if (k == 'jichangLeft' || k == 'kongzhiLeft') {
        if (k == 'kongzhiLeft') msg = '控制室净尺寸'
        this.domTxtGrapBJ[k + 'msg'] = new PIXI.Text(msg, {
          fontFamily: 'Arial',
          fontSize: this.zihao,
          fill: data.styles[this.domTxtJsonBJ[k].style].color,
          align: 'center'
        })
        this.domTxtGrapBJ[k + 'msg'].x =
          (this.domTxtJsonBJ[k].x + 40) * this.beishu +
          this.domJson['机房'].position.x
        this.domTxtGrapBJ[k + 'msg'].y =
          (this.domTxtJsonBJ[k].y + 80) * this.beishu +
          this.domJson['机房'].position.y
        this.domTxtGrapBJ[k + 'msg'].rotation =
          (this.domTxtJsonBJ[k].rotation / 180) * Math.PI
        this.app.stage.addChild(this.domTxtGrapBJ[k + 'msg'])
      }
      if (k == 'jichangTop' || k == 'kongzhiBottom') {
        if (k == 'kongzhiBottom') msg = '控制室净尺寸'
        this.domTxtGrapBJ[k + 'msg'] = new PIXI.Text(msg, {
          fontFamily: 'Arial',
          fontSize: this.zihao,
          fill: data.styles[this.domTxtJsonBJ[k].style].color,
          align: 'center'
        })
        this.domTxtGrapBJ[k + 'msg'].x =
          (this.domTxtJsonBJ[k].x - 80) * this.beishu +
          this.domJson['机房'].position.x
        this.domTxtGrapBJ[k + 'msg'].y =
          (this.domTxtJsonBJ[k].y + 40) * this.beishu +
          this.domJson['机房'].position.y
        this.domTxtGrapBJ[k + 'msg'].rotation =
          (this.domTxtJsonBJ[k].rotation / 180) * Math.PI
        this.app.stage.addChild(this.domTxtGrapBJ[k + 'msg'])
      }

      if (k == 'jianchachuangleft' && this.gantryAngle != 0) {
        k = 'jianchachuang'
        this.domTxtGrapBJ[k] = new PIXI.Text(this.domTxtJsonBJ[k].num, {
          fontFamily: 'Arial',
          fontSize: this.zihao,
          fill: data.styles[this.domTxtJsonBJ[k].style].color,
          align: 'center'
        })
        this.domTxtGrapBJ[k].x =
          this.domTxtJsonBJ[k].x * this.beishu + this.domJson['机房'].position.x
        this.domTxtGrapBJ[k].y =
          this.domTxtJsonBJ[k].y * this.beishu + this.domJson['机房'].position.y
        // this.domTxtGrapBJ[k].scale.x = 0.3;
        // this.domTxtGrapBJ[k].scale.y = 0.3;
        this.domTxtGrapBJ[k].rotation =
          (this.domTxtJsonBJ[k].rotation / 180) * Math.PI
        this.app.stage.addChild(this.domTxtGrapBJ[k])
      }
    },
    editBiaozhu(name) {
      this.app.stage.removeChild(this.domGrapBJ[name])
      this.domGrapBJ[name].destroy({
        children: true
      })
      this.app.stage.removeChild(this.domTxtGrapBJ[name])
      this.domTxtGrapBJ[name].destroy({
        children: true
      })
      this.makeBiaozhu(this.domJsonBJ[name])
    },
    makeBiaozhuJson(name, x, y, x2, y2, fangxiang, type = 0) {
      // console.log(name, x, y, x2, y2, fangxiang,type = 0, '99999999999999999')
      if(name === 'jianchachuangtop'){
      }
      this.domJsonBJ[name] = {
        name: name,
        position: {
          x: this.domJson['机房'].position.x,
          y: this.domJson['机房'].position.y
        },
        rotation: 0,
        move: false,
        styles: [
          {
            weight: 2,
            color: '0x000000',
            alpha: 1
          },
          {
            weight: 2,
            color: '0x1FFF1F',
            alpha: 1
          }
        ],
        child: []
      }
      if (type > 0) type = type * 2
      let addlen = 75 + 35 * type
      let pyx = 1,
        pyy = 1
      if (x == x2) {
        x += addlen * fangxiang
        x2 += addlen * fangxiang
        pyx = -1
        pyy = -1
      } else if (y == y2) {
        y += addlen * fangxiang
        y2 += addlen * fangxiang
        pyx = 1
        pyy = -1
      }
      let pianyi = 10
      let sty = 0
      // if(y==y2&&y==){

      // }
      if (name.indexOf('jianchachuang') == 0) sty = 1
      this.domJsonBJ[name].child.push({
        style: sty,
        line: {
          x: x,
          y: y,
          x2: x2,
          y2: y2
        }
      })
      this.domJsonBJ[name].child.push({
        style: sty,
        line: {
          x: x + pianyi * pyx,
          y: y + pianyi * pyy,
          x2: x + pianyi * pyx * -1,
          y2: y + pianyi * pyy * -1
        }
      })
      this.domJsonBJ[name].child.push({
        style: sty,
        line: {
          x: x2 + pianyi * pyx,
          y: y2 + pianyi * pyy,
          x2: x2 + pianyi * pyx * -1,
          y2: y2 + pianyi * pyy * -1
        }
      })
      if (x == x2) {
        this.domJsonBJ[name].child.push({
          style: sty,
          line: {
            x: x,
            y: y,
            x2: x + 45 * fangxiang * -1,
            y2: y
          }
        })
        this.domJsonBJ[name].child.push({
          style: sty,
          line: {
            x: x2,
            y: y2,
            x2: x2 + 45 * fangxiang * -1,
            y2: y2
          }
        })
        if (name.indexOf('jianchachuang') == 0) {
          this.domJsonBJ[name].child.push({
            style: sty,
            dash: {
              x: x2,
              y: y2,
              x2: this.jijiax,
              y2: y2
            }
          })
        }
      }
      if (y == y2) {
        this.domJsonBJ[name].child.push({
          style: sty,
          line: {
            x: x,
            y: y,
            x2: x,
            y2: y + 45 * fangxiang * -1
          }
        })
        this.domJsonBJ[name].child.push({
          style: sty,
          line: {
            x: x2,
            y: y2,
            x2: x2,
            y2: y2 + 45 * fangxiang * -1
          }
        })
        if (name.indexOf('jianchachuang') == 0) {
          let dashx = x2
          if (
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness) <
            x
          )
            dashx = x
          this.domJsonBJ[name].child.push({
            style: sty,
            dash: {
              x: dashx,
              y: y2,
              x2: dashx,
              y2: this.jijiay + 200
            }
          })
          this.jijiax = this.jijiax * 1
          this.jijiay = this.jijiay * 1
          this.domJsonBJ[name].child.push({
            style: 1,
            line: {
              x:
                this.jijiax +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y:
                this.jijiay +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              x2:
                (200 -
                  this.dataObj.machineRoomForm.wallThickness -
                  Number(this.dataObj.machineRoomForm.decorateThickness)) *
                  Math.sin(-(this.gantryAngle / 180) * Math.PI) +
                this.jijiax +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness),
              y2:
                (200 -
                  this.dataObj.machineRoomForm.wallThickness -
                  Number(this.dataObj.machineRoomForm.decorateThickness)) *
                  Math.cos(-(this.gantryAngle / 180) * Math.PI) +
                this.jijiay +
                this.dataObj.machineRoomForm.wallThickness +
                Number(this.dataObj.machineRoomForm.decorateThickness)
            }
          })
          if (this.gantryAngle != 0) {
            this.domJsonBJ[name].child.push({
              style: 1,
              line: {
                x: dashx,
                y: this.jijiay + 200,
                x2:
                  (200 -
                    this.dataObj.machineRoomForm.wallThickness -
                    Number(this.dataObj.machineRoomForm.decorateThickness)) *
                    Math.sin(-(this.gantryAngle / 180) * Math.PI) +
                  this.jijiax +
                  this.dataObj.machineRoomForm.wallThickness +
                  Number(this.dataObj.machineRoomForm.decorateThickness),
                y2:
                  (200 -
                    this.dataObj.machineRoomForm.wallThickness -
                    Number(this.dataObj.machineRoomForm.decorateThickness)) *
                    Math.cos(-(this.gantryAngle / 180) * Math.PI) +
                  this.jijiay +
                  this.dataObj.machineRoomForm.wallThickness +
                  Number(this.dataObj.machineRoomForm.decorateThickness)
              }
            })
            this.domJsonBJ[name].child.push({
              style: 1,
              fill: '0x1FFF1F',
              circle: {
                x: dashx,
                y: this.jijiay + 200,
                radius: 10
              }
            })
            this.domJsonBJ[name].child.push({
              style: 1,
              fill: '0x1FFF1F',
              circle: {
                x:
                  (200 -
                    this.dataObj.machineRoomForm.wallThickness -
                    Number(this.dataObj.machineRoomForm.decorateThickness)) *
                    Math.sin(-(this.gantryAngle / 180) * Math.PI) +
                  this.jijiax +
                  this.dataObj.machineRoomForm.wallThickness +
                  Number(this.dataObj.machineRoomForm.decorateThickness),
                y:
                  (200 -
                    this.dataObj.machineRoomForm.wallThickness -
                    Number(this.dataObj.machineRoomForm.decorateThickness)) *
                    Math.cos(-(this.gantryAngle / 180) * Math.PI) +
                  this.jijiay +
                  this.dataObj.machineRoomForm.wallThickness +
                  Number(this.dataObj.machineRoomForm.decorateThickness),
                radius: 10
              }
            })

            this.domTxtJsonBJ['jianchachuang'] = {
              style: 1,
              x:
                Math.min(
                  dashx,
                  (200 -
                    this.dataObj.machineRoomForm.wallThickness -
                    Number(this.dataObj.machineRoomForm.decorateThickness)) *
                    Math.sin(-(this.gantryAngle / 180) * Math.PI) +
                    this.jijiax +
                    this.dataObj.machineRoomForm.wallThickness +
                    Number(this.dataObj.machineRoomForm.decorateThickness)
                ) -
                20 +
                Math.abs(
                  dashx -
                    ((200 -
                      this.dataObj.machineRoomForm.wallThickness -
                      Number(this.dataObj.machineRoomForm.decorateThickness)) *
                      Math.sin(-(this.gantryAngle / 180) * Math.PI) +
                      this.jijiax +
                      this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness))
                ) /
                  2,
              y:
                Math.min(
                  this.jijiay + 200,
                  (200 -
                    this.dataObj.machineRoomForm.wallThickness -
                    Number(this.dataObj.machineRoomForm.decorateThickness)) *
                    Math.cos(-(this.gantryAngle / 180) * Math.PI) +
                    this.jijiay +
                    this.dataObj.machineRoomForm.wallThickness +
                    Number(this.dataObj.machineRoomForm.decorateThickness)
                ) -
                20 +
                Math.abs(
                  this.jijiay +
                    200 -
                    ((200 -
                      this.dataObj.machineRoomForm.wallThickness -
                      Number(this.dataObj.machineRoomForm.decorateThickness)) *
                      Math.cos(-(this.gantryAngle / 180) * Math.PI) +
                      this.jijiay +
                      this.dataObj.machineRoomForm.wallThickness +
                      Number(this.dataObj.machineRoomForm.decorateThickness))
                ) /
                  2,
              num: this.gantryAngle,
              rotation:
                (180 - this.gantryAngle) / 2 - (180 - this.gantryAngle - 90)
            }
            console.log('this.gantryAngle', this.gantryAngle)
          }
        }
      }
      if (x == x2) {
        this.domTxtJsonBJ[name] = {
          style: sty,
          x: x + (x2 - x) / 2 - 40 > 0 ? Number((x + (x2 - x) / 2 - 40).toString().match(/^\d+(?:\.\d{0,2})?/)) : (x + (x2 - x) / 2 - 40).toFixed(0) ,
          y: (y + (y2 - y) / 2 + 30) > 0 ? Number((y + (y2 - y) / 2 + 30).toString().match(/^\d+(?:\.\d{0,2})?/)) : (y + (y2 - y) / 2 + 30).toFixed(0),
          num: (y2 - y) > 0 ? Number((y2 - y).toString().match(/^\d+(?:\.\d{0,2})?/)) : (y2 - y).toFixed(0),
          // x: x + (x2 - x) / 2 - 40,
          // y: y + (y2 - y) / 2 + 30,
          // num: y2 - y,
          rotation: -90
        }
      } else {
        this.domTxtJsonBJ[name] = {
          style: sty,
          // x: x + (x2 - x) / 2 - 30,
          // y: y + (y2 - y) / 2 - 40,
          // num: (x2 - x).toFixed(1),
          x: (Number((x + (x2 - x) / 2 - 30).toString().match(/^\d+(?:\.\d{0,2})?/))) <= 0 ? (x + (x2 - x) / 2 - 30).toFixed(0) : (Number((x + (x2 - x) / 2 - 30).toString().match(/^\d+(?:\.\d{0,2})?/))),
          y:( Number((y + (y2 - y) / 2 - 40).toString().match(/^\d+(?:\.\d{0,2})?/))) <= 0 ? (y + (y2 - y) / 2 - 40).toFixed(0) : ( Number((y + (y2 - y) / 2 - 40).toString().match(/^\d+(?:\.\d{0,2})?/))) ,
          num: (Number((x2 - x).toString().match(/^\d+(?:\.\d{0,2})?/))) <= 0 ? (x2 - x).toFixed(0) : (Number((x2 - x).toString().match(/^\d+(?:\.\d{0,2})?/))),
          // num: x2 - x,
          rotation: 0
        }
        // console.log(y + (y2 - y) / 2 - 40, '机床x坐标')
        // console.log(Number((y + (y2 - y) / 2 - 40).toString().match(/^\d+(?:\.\d{0,2})?/)), '机床x坐标')
      }
      
    },
    drawDash(target, x1, y1, x2, y2, dashLength = 10, spaceLength = 10) {
      let x = x2 - x1
      let y = y2 - y1
      let hyp = Math.sqrt(x * x + y * y)
      let units = hyp / (dashLength + spaceLength)
      let dashSpaceRatio = dashLength / (dashLength + spaceLength)
      let dashX = (x / units) * dashSpaceRatio
      let spaceX = x / units - dashX
      let dashY = (y / units) * dashSpaceRatio
      let spaceY = y / units - dashY
      target.moveTo(x1, y1)
      while (hyp > 0) {
        x1 += dashX
        y1 += dashY
        hyp -= dashLength
        if (hyp < 0) {
          x1 = x2
          y1 = y2
        }
        target.lineTo(x1, y1)
        x1 += spaceX
        y1 += spaceY
        target.moveTo(x1, y1)
        hyp -= spaceLength
      }
      target.moveTo(x2, y2)
    },
    loading() {
      if (this.$refs.progressRef && this.flag == true) {
        this.$refs.progressRef.value = this.$refs.progressRef.value + 2
        if (this.$refs.progressRef.value == '100') {
          this.$refs.progressRef.value = '20'
        }
      }
    },
    getTaskStatus() {
      GetTaskStatus({ id: this.taskId }).then((r) => {
        if (r.data.data != null) {
          if (r.data.data.state == 0) {
            //未处理
          } else if ((r.data.data.state == 1 || r.data.data.state == 3) && (r.data.data.pdf !== null || r.data.data.dwg !== null) ) {
            //完成
            clearInterval(this.timer)
            clearInterval(this.timer1)
            this.timer = null
            if (this.$refs.progressRef) {
              this.$refs.progressRef.value = '100'
            }
            this.flag = false
            clearInterval(this.timer5)
            this.pdf = r.data.data.pdf
            this.dwg = r.data.data.dwg
            this.pdfToken= AES.encrypt(r.data.data.pdf).toString().split('/').join('').split('+').join('')
            this.dwgToken= AES.encrypt(r.data.data.dwg).toString().split('/').join('').split('+').join('')
            this.dwgFileName = r.data.data.fileName
            this.pdfFileName = r.data.data.fileName.replace('dwg', 'pdf')
          } else {
            //异常
          }
        }
      })
    },
    loadPdf() {
      window.open(this.baseUrlApi + 'File/DwgOrPDF/' + this.pdf+ '/' + this.pdfToken +'/' + this.pdfFileName)
        // axios.post(this.baseUrlApi + 'File/DwgOrPDF', qs.stringify(
        //   {fileId:this.pdf,
        //   token:AES.encrypt(this.pdf).toString()
        // }))
        // .then(function (response) {
        //   console.log(response);
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
        //  let url = this.baseUrlApi + 'File/DwgOrPDF/' + this.pdf + '/' + this.pdfFileName;
        //     function createObjectURL(object) {
        //         return window.URL
        //         ? window.URL.createObjectURL(object)
        //         : window.webkitURL.createObjectURL(object);
        //     }
        //     var xhr = new XMLHttpRequest();
        //     var formData = new FormData();
        //     xhr.open("get", url); //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
        //     xhr.setRequestHeader("authorization", localStorage.getItem("Authorization"));
        //     xhr.responseType = "blob";
        //     var fileName=this.pdfFileName
        //     xhr.onload = function(e) {
        //         if (this.status == 200) {
        //             var blob = this.response;
        //             var filename =fileName;
        //             if (window.navigator.msSaveOrOpenBlob) {
        //                 navigator.msSaveBlob(blob, filename);
        //             } 
        //             else {
        //                 var a = document.createElement("a");
        //                 var url = createObjectURL(blob);
        //                 a.href = url;
        //                 a.download = filename;
        //                 document.body.appendChild(a);
        //                 a.click();
        //                 window.URL.revokeObjectURL(url);
        //             }
            
        //         }
        //     };
        //     xhr.send(formData);
    },
    loadDwg() {
      window.open(this.baseUrlApi + 'File/DwgOrPDF/' + this.dwg+ '/' +  this.dwgToken +'/' + this.dwgFileName)
        //  let url =this.baseUrlApi + 'File/DwgOrPDF/' + this.dwg + '/' + this.dwgFileName;
        //     function createObjectURL(object) {
        //         return window.URL
        //         ? window.URL.createObjectURL(object)
        //         : window.webkitURL.createObjectURL(object);
        //     }
        //     var xhr = new XMLHttpRequest();
        //     var formData = new FormData();
        //     xhr.open("get", url); //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
        //     xhr.setRequestHeader("authorization", localStorage.getItem("Authorization"));
        //     xhr.responseType = "blob";
        //     var fileName=this.dwgFileName
        //     xhr.onload = function(e) {
        //         if (this.status == 200) {
        //             var blob = this.response;
        //             var filename =fileName;
        //             if (window.navigator.msSaveOrOpenBlob) {
        //                 navigator.msSaveBlob(blob, filename);
        //             } 
        //             else {
        //                 var a = document.createElement("a");
        //                 var url = createObjectURL(blob);
        //                 a.href = url;
        //                 a.download = filename;
        //                 document.body.appendChild(a);
        //                 a.click();
        //                 window.URL.revokeObjectURL(url);
        //             }
        //         }
        //     };
        //     xhr.send(formData);
    },
    sendMail() {
      this.$refs.popupRef.openPopup()
      this.emailAddressList = [
        JSON.parse(localStorage.getItem('data')).ThirdPartyProject.pmEmail
      ]
      this.checkedValue = []
    },
    choseName(item) {
      Array.prototype.indexOf = function (val) {
        for (var i = 0; i < this.length; i++) {
          if (this[i] == val) return i
        }
        return -1
      }
      Array.prototype.remove = function (val) {
        var index = this.indexOf(val)
        if (index > -1) {
          this.splice(index, 1)
        }
      }
      if (!this.checkedValue.includes(item)) {
        this.checkedValue.push(item)
      } else {
        this.checkedValue.remove(item)
      }
    },
    closeIcon() {},
    popupConfirm() {
      this.$refs.popupRef.closePopup()
      this.emailAddressList.push(...this.checkedValue)
      SendEmail({
        id: this.taskId,
        selectEmailAddress: this.emailAddressList
      }).then((res) => {
        if (res.data.success) {
          Toast.success(res.data.message)
        }
      })
    },
    popupCancel() {
      this.$refs.popupRef.closePopup()
    },
    focusEvent(val) {
      this.$refs.angleRef.openPopup()
      this.$nextTick(() => {
        this.$refs.focusRef.focus()
      })
      val == '机架X坐标' ? (this.fieldTitle = '机架X坐标') : (this.fieldTitle = '机架Y坐标')
      this.fieldValue = ''
    },
    enterEvent() {
      this.angleConfirm()
    },
    angleConfirm() {
      this.fieldTitle == '机架X坐标' ? (this.jijiaxRight = this.fieldValue) : (this.jijiay = this.fieldValue)
       if (this.dataObj.point == 1) {
        this.jijiax = this.dataObj.machineRoomForm.length - this.jijiaxRight 
        }else{
      this.jijiax = this.jijiaxRight
        }
      this.$refs.angleRef.closePopup()
    },
    weitiao(x, y, r, flg) {
      if (this.isweitiao == 0) {
        console.log(this.isweitiao)
        this.isweitiao = 1
        this.domGrap[this.jijiadom].x = x * this.beishu + this.bianjie[0]
        this.domGrap[this.jijiadom].y = y * this.beishu + this.bianjie[1]
        this.domGrap[this.jijiadom].rotation = (r / 180) * Math.PI

        this.app.stage.removeChild(this.domGrapBJ['jianchachuangtop'])
        if (this.domGrapBJ['jianchachuangtop'])
          this.domGrapBJ['jianchachuangtop'].destroy({
            children: true
          })
        this.app.stage.removeChild(this.domGrapBJ['jianchachuangleft'])
        if (this.domGrapBJ['jianchachuangleft'])
          this.domGrapBJ['jianchachuangleft'].destroy({
            children: true
          })
        this.app.stage.removeChild(this.domTxtGrapBJ['jianchachuangtop'])
        if (this.domGrapBJ['jianchachuangtop'])
          this.domTxtGrapBJ['jianchachuangtop'].destroy({
            children: true
          })
        this.app.stage.removeChild(this.domTxtGrapBJ['jianchachuangleft'])
        if (this.domGrapBJ['jianchachuangleft'])
          this.domTxtGrapBJ['jianchachuangleft'].destroy({
            children: true
          })
        this.app.stage.removeChild(this.domTxtGrapBJ['jianchachuang'])
        if (this.domGrapBJ['jianchachuang'])
          this.domTxtGrapBJ['jianchachuang'].destroy({
            children: true
          })
        if (this.jcbjwz == 0) {
          this.makeBiaozhuJson(
            'jianchachuangtop',
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              Number(x),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            -1
          )
          this.makeBiaozhuJson(
            'jianchachuangleft',
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              Number(y),
            -1
          )
        } else {
          this.makeBiaozhuJson(
            'jianchachuangtop',
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              Number(x),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              this.dataObj.machineRoomForm.length,
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            -1
          )
          this.makeBiaozhuJson(
            'jianchachuangleft',
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              this.dataObj.machineRoomForm.length,
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness),
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              this.dataObj.machineRoomForm.length,
            this.dataObj.machineRoomForm.wallThickness +
              Number(this.dataObj.machineRoomForm.decorateThickness) +
              Number(y),
            1
          )
        }

        // console.log(this.domJsonBJ['jianchachuangtop'])
        this.makeBiaozhu(this.domJsonBJ['jianchachuangtop'])
        this.makeBiaozhu(this.domJsonBJ['jianchachuangleft'])
        this.dataObj.mainDevice.point.x = Number(x)
        this.dataObj.mainDevice.point.y = Number(y)
        this.dataObj.mainDevice.angle = r
        // console.log(this.dataObj);
        this.dataObj.extJson.deviceExportData.forEach((elem) => {
          if (elem.fileId == this.jijiadom) {
            elem.point.x = Number(x)
            elem.point.y = Number(y)
            elem.angle.value = r
          }
        })

        this.isweitiao = 0
      }
    }
  },
  watch: {
    status(newVal,oldVal){
      console.log(newVal, 'newVal------------------')
      console.log(oldVal, 'oldVal------------------')
      this.status = this.status
    },
    jijiax(newVal, oldVal) {
      localStorage.setItem('jijiax', newVal)
      console.log(newVal, oldVal,4444444)
      //  if (this.dataObj.point == 1 ) {//定位点右时机架x坐标
      // newVal = this.dataObj.machineRoomForm.length - newVal 
      //   }
      if (
        this.domGrap[this.jijiadom] != undefined &&
        this.status == 'adjustment'
      ) {
        this.weitiao(newVal, this.jijiay, this.gantryAngle)
      }
    },
    jijiay(newVal, oldVal) {
      localStorage.setItem('jijiay', newVal)
      if (
        this.domGrap[this.jijiadom] != undefined &&
        this.status == 'adjustment'
      ) {
        this.weitiao(this.jijiax, newVal, this.gantryAngle)
      }
    },
    gantryAngle(newVal, oldVal) {
      localStorage.setItem('gantryAngle', newVal)
      if (
        this.domGrap[this.jijiadom] != undefined &&
        this.status == 'adjustment'
      ) {
        this.weitiao(this.jijiax, this.jijiay, newVal)
      }
    }
  }
}
</script>
<style type="text/css" lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.btn {
  position: absolute;
}

li {
  list-style: none;
}

#pixi {
  width: 100%;
  // background-color: #000;
  position: relative;
}
.pixi {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  margin-top: 2px;
}

.img-box {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  li {
    text-align: center;
  // column-count: 3;

    p {
      color: rgba(0, 0, 0, 0.5);
      font-size: 20px;
    }
  }
}

.img-item {
  width: 100px;
  height: 100px;
}

.content-btn {
  display: inline-block;
  width: 200px;
  height: 60px;
  background: #ec6602;
  border-radius: 30px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  font-size: 26px;
  margin-left: 68%;
  // margin-bottom: 20px;
}

.dragBox {
  padding: 15px 26px;
  margin-top: 10px;
  width: 100%;
  // height: 400px;
  background-color: #fff;

  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 50px;
    color: #000000;
  }

  .content-btn {
    margin-top: 118px;
  }
}

.planBox {
  padding: 15px 26px;
  margin-top: 10px;
  width: 100%;
  // height: 330px;
  background-color: #fff;

  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 80px;
    color: #000000;
  }

  ul {
    display: flex;
    // justify-content: space-between;
    justify-content: space-around;

    li {
      width: 184px;
      height: 204px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .act {
      width: 210px;
      height: 230px;
      margin-top: -16px;
    }
  }

  .content-btn {
    margin-top: 40px;
  }
}

.adjustmentBox {
  padding: 15px 26px;
  margin-top: 10px;
  width: 100%;
  // height: 330px;
  background-color: #fff;
  position: relative;
  .updataBtn {
    position: absolute;
    right: 25px;
    top: 10px;
    width: 80px;
    height: 40px;
    background: #ec6602;
    border-radius: 30px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
  }
  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    color: #000000;
  }

  .handPosition {
    line-height: 50px;
    font-weight: bold;
    font-size: 24px;
    color: #404040;
    display: flex;
    justify-content: space-between;

    .rightHand {
      margin-left: 20px;
    }
  }

  .content-btn {
    margin-top: 75px;
  }

  .sliderBox {
    margin-top: 25px;
    width: 100%;
    height: 46px;
    background: url('./../assets/images/angle.png') no-repeat;
    background-size: 100% 100%;
  }
}

.custom-button {
  width: 8px;
  height: 35px;
  background: #ec6602;
}

::v-deep .van-slider {
  background-color: #fff;
  width: 97%;
  margin: 0 auto;
}

::v-deep .van-radio .van-radio__label {
  line-height: 50px;
  font-size: 24px;
  font-weight: bold;
  color: #404040;
}

::v-deep .van-field__label {
  line-height: 50px;
  font-weight: bold;
  font-size: 24px;
  color: #404040;
  width: 300px;
}

::v-deep .van-field__control {
  line-height: 50px;
  font-size: 24px;
  font-weight: bold;
  color: #404040;
}

.dangerBox {
  padding: 15px 26px;
  margin-top: 10px;
  width: 100%;
  // height: 330px;
  background-color: #fff;

  .list {
    .title {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }

    ul {
      width: 100%;
      height: 250px;
      overflow: auto;
      padding: 15px 0;
      padding-right: 30px;

      li {
        display: flex;
        justify-content: space-between;
        color: #000000;
        font-size: 24px;
        padding: 14px 0;
        border-bottom: 1px solid #eeeeee;

        &:nth-last-child(1) {
          border-bottom: 0;
        }

        img {
          width: 23px;
          height: 27px;
          margin-top: 3px;
        }
      }
    }
  }

  .content-btn {
    margin-top: 56px;
  }
}

.angleBox {
  padding: 15px 26px;
  margin-top: 10px;
  width: 100%;
  // height: 200px;
  background-color: #fff;
  .content-btn {
    margin-top: 235px;
  }

  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 60px;
    color: #000000;
  }

  .rotate {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 0;

    .rotateIcon {
      margin-top: 15px;
    }

    img {
      width: 30px;
      height: 36px;
      margin-left: 30px;
    }
  }
}

.two {
  position: relative;
  img {
    width: 56px;
    height: 48px;
    z-index: 1;
    position: absolute;
    right: 4%;
    top: 10%;
  }
}

.risktitle {
  text-align: center;
  font-size: 36px;
  line-height: 150px;
  color: #000000;
}

input[type='text'],
#btn1,
#btn2 {
  box-sizing: border-box;
  text-align: left;
  font-size: 24px;
  height: 70px;
  border-radius: 10px;
  border: 1px solid #ededed;
  background-color: #ededed;
  color: #000000;
  -web-kit-appearance: none;
  -moz-appearance: none;
  display: block;
  outline: 0;
  padding: 0 15px;
  text-decoration: none;
  width: 90%;
  margin-left: 5%;
}

.riskList {
  margin-top: 5%;
  padding-left: 7%;
  padding-right: 3%;
  margin-right: 4%;
  height: 50%;
  overflow: auto;

  li {
    font-size: 20px;
    font-weight: bold;
    color: #404040;
    margin-bottom: 30px;
  }

  .checkedAct {
    background-color: rgba(236, 102, 2, 0.2);
  }
}

.btnbox {
  display: flex;
  width: 90%;
  margin: 20px auto;
  justify-content: space-around;

  .content-btn {
    display: inline-block;
    width: 200px;
    height: 60px;
    background: #ec6602;
    border-radius: 30px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
    font-size: 26px;
    margin-left: 0;
    // margin-bottom: 20px;
  }

  .cancel {
    background: #d3d3d3;
    color: #000000;
  }
}

.box {
  width: 100%;
  height: calc(100vh - 98px);
  background-color: #fff;
}
.progressBox {
  padding: 80px;
  padding-top: 200px;
}
progress {
  width: 100%;
  height: 30px;
  color: #ec6602;
  background: #efeff4;
  border-radius: 15px;
}
/* 表示总长度背景色 */
progress::-webkit-progress-bar {
  background-color: #d3d3d3;
  border-radius: 15px;
}
/* 表示已完成进度背景色 */
progress::-webkit-progress-value {
  background: #ec6602;
  border-radius: 15px;
}
.textbox {
  text-align: center;
  .p {
    font-family: PangMenZhengDao;
    color: rgba(0, 0, 0, 0.7);
    font-size: 40px;
    font-weight: 600;
    line-height: 80px;
  }
  .span {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #000000;
    margin-right: 3px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  .span1,
  .span6 {
    background-color: #d1d1d1;
  }
  .span2,
  .span5 {
    background-color: #989898;
  }
  .span3,
  .span4 {
    background-color: #666666;
  }
}
.finishbox {
  text-align: center;
  padding: 80px;
  padding-top: 232px;
  .p {
    font-family: PangMenZhengDao;
    color: rgba(0, 0, 0, 0.7);
    font-size: 40px;
    font-weight: 600;
    line-height: 80px;
  }
  .span {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #000000;
    margin-right: 3px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  .span1,
  .span6 {
    background-color: #d1d1d1;
  }
  .span2,
  .span5 {
    background-color: #989898;
  }
  .span3,
  .span4 {
    background-color: #666666;
  }
}
.btnbox1 {
  padding: 40px;
  padding-top: 55%;
  .button {
    width: 100%;
    height: 85px;
    background: #ec6602;
    border-radius: 43px;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    line-height: 85px;
    text-align: center;
  }
  .load {
    margin-bottom: 20px;
  }
  .mail {
    background: #ffffff;
    color: #ec6602;
    border: 2px solid #ec6602;
  }
  .disabledStyle {
    background: #dfdfdf;
    color: #ffffff;
    border: 0px;
  }
}
.popup-content {
  text-align: center;
  padding: 50px 0;
  padding-bottom: 40px;
  height: 100%;
  width: 100%;
  .contentBox {
    width: 100%;
    height: 80%;
    padding-bottom: 15px;
    overflow: auto;
  }
  .title {
    font-size: 36px;
    color: #000000;
    line-height: 60px;
    font-family: Source Han Sans CN;
  }
  .desc {
    font-size: 24px;
    color: #000000;
    opacity: 0.5;
  }
  .desc1 {
    font-size: 24px;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 30px;
  }
  .ruleText {
    color: #ef0101;
    font-size: 22px;
    line-height: 56px;
    font-weight: bold;
  }
  .value {
    margin-top: 60px;
    // font-size: 48px;
    color: #000000;
    opacity: 0.75;
    line-height: 60px;
  }
  .line {
    margin: 0 auto;
    width: 400px;
    height: 1px;
    background: #868686;
    opacity: 0.5;
  }
  .content-item {
    font-size: 30px;
    font-weight: 500;
    color: #404040;
    line-height: 70px;
    margin-left: 30px;
    span {
      display: inline-block;
      width: 30px;
      height: 20px;
    }
    .checked {
      background: url('./../assets/images/checked.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .content-btn {
    display: inline-block;
    margin: 32px auto;
    bottom: 0;
    width: 200px;
    height: 60px;
    background: #ec6602;
    border-radius: 30px;
    line-height: 60px;
    color: #ffffff;
    font-size: 26px;
    position: absolute;
    bottom: 0;
    left: 33%;
  }
  .zhubtn {
    left: 53%;
  }
  .cancel {
    background: #d3d3d3;
    position: absolute;
    bottom: 0;
    left: 13%;
    color: #000000;
  }
}
</style>
